import CustomError from "../../CustomError";

export class YearService {
    url
    constructor(url) {
        this.url = url;
    }

    async fetchJson(url, options) {
        try {
            const response = await fetch(url, options);

            if (response.ok) {
                return await response.json();
            }
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async getAllYears() {
        try {
            return await this.fetchJson(`${this.url}`);
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async getYearById(id) {
        try {

            return await this.fetchJson(`${this.url}/${id}`);
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async createYear(year) {
        try {
            return await this.fetchJson(`${this.url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(year),
            });
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async updateYear(year) {
        try {
            return await this.fetchJson(`${this.url}/${year.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(year),
            });
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async removeYear(yearId) {
        try {
            return await this.fetchJson(`${this.url}/${yearId}`, {
                method: 'DELETE'
            })
        } catch (e) {
            const customError = new CustomError(e.message, e.timestamp, e.details, e.status)
            throw customError.toJSON()
        }
    }
}