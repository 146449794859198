import CustomError from "../../CustomError";

export class InternshipTimesService {
    url
    constructor(url) {
        this.url = url;
    }

    async fetchJson(url, options) {
        try {
            const response = await fetch(url, options);

            if (response.ok) {

                return await response.json();
            }
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async getInternshipTimeById(id) {
        try {
            return await this.fetchJson(`${this.url}/${id}`);
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async getInternshipTimeByInternshipPageable(internshipId, pageNo) {
        try {
            return await this.fetchJson(`${this.url}/internship/pageable/${internshipId}?pageNo=${parseInt(pageNo) -1}&pageSize=10&sortBy=date&sortDir=`);
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async getTotalPages(internshipId) {
        try {
            return await this.fetchJson(`${this.url}/internship/pageable/total/${internshipId}?&pageSize=10`);
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async getInternshipTimeByInternship(internshipId) {
        try {
            return await this.fetchJson(`${this.url}/internship/${internshipId}`);
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async createInternshipTime(internshipTime, internshipId) {
        try {
            return await this.fetchJson(`${this.url}/${internshipId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(internshipTime),
            });
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async updateInternshipTimes(internship) {
        try {
            return await this.fetchJson(`${this.url}/${internship.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(internship),
            });
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async removeInternshipTime(internShipTimeId) {
        try {
            return await this.fetchJson(`${this.url}/${internShipTimeId}`, {
                method: 'DELETE'
            })
        } catch (e) {
            const customError = new CustomError(e.message, e.timestamp, e.details, e.status)
            throw customError.toJSON()
        }
    }
}
