<template>
  <div>
    <button @click.prevent="back" class="mb-2 dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button">Terug</button>
    <form class="flex flex-col my-4 gap-2">
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': user.email }" for="email">Email</label>
        <input v-model="user.email" @input="validateEmail" :class="emailClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="email"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{emailError}}</p>
      </div>
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': user.firstname }" for="firstname">Voornaam</label>
        <input v-model="user.firstname" @input="validateFirstname" :class="firstnameClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="firstname"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ firstnameError}}</p>
      </div>
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': user.lastname }" for="lastname">Achternaam</label>
        <input v-model="user.lastname" @input="validateLastname" :class="lastnameClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="lastname"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ lastnameError}}</p>
      </div>
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': user.phoneNumber }" for="phoneNumber">Telefoonnummer</label>
        <input v-model="user.phoneNumber" @input="validatePhoneNumber" :class="phoneNumberClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="phoneNumber"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ phoneNumberError}}</p>
      </div>
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': user.hourlyWage }" for="hourlyWage">Uurloon</label>
        <input v-model="user.hourlyWage" @input="validateHourlyWage" :class="hourlyWageClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="hourlyWage"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ hourlyWageError}}</p>
      </div>
      <div v-if="selected === '4'" class="flex flex-col relative input-container">
        <label for="data"  class="dark:bg-stone-800 dark:text-stone-100">
          <select v-model="user.childId"  class="w-full select-none focus:outline-none dark:bg-stone-800 dark:text-stone-100 input-field focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent hover:border-b-orange-600  border-t hover:border-t-orange-600 border-l hover:border-l-orange-600  border-r hover:border-r-orange-600" name="data" id="data">
            <option disabled value="0">Selecteer een rol</option>
            <option :value="0"></option>
            <option v-for="user in users.list" :key="user.id" :value="`${user.id}`">{{ user.email }}</option>
          </select>
        </label>
      </div>
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': user.password }" for="password">Wachtwoord</label>
        <input v-model="user.password" @input="validatePassword" :class="passwordClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="password" id="password"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ passwordError}}</p>
      </div>
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': user.extraPassword }" for="extraPassword">Herhaal Wachtwoord</label>
        <input v-model="user.extraPassword" @input="validateExtraPassword" :class="passwordExtraClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="password" id="extraPassword"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ passwordExtraError}}</p>
      </div>
      <div class="flex w-full justify-between items-center">
        <button class="w-7/12" type="submit" :disabled="emailValid === false || firstnameValid === false || lastnameValid === false || phoneNumberValid === false || hourlyWageValid === false || passwordValid === false || passwordExtraValid === false || !user.firstname || !user.email || !user.lastname || !user.phoneNumber || !user.hourlyWage || !user.password || !user.extraPassword" @click.prevent="createUser" :class="buttonClass">Aanmaken</button>
        <label for="data"  class="dark:bg-stone-800 dark:text-stone-100 w-3/12 ">
          <select v-model="selected"  class="w-full select-none focus:outline-none dark:bg-stone-800 dark:text-stone-100 input-field focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent hover:border-b-orange-600  border-t hover:border-t-orange-600 border-l hover:border-l-orange-600  border-r hover:border-r-orange-600" name="data" id="data">
            <option disabled value="0">Selecteer een rol</option>
            <option v-for="role in roles.list" :key="role.id" :value="`${role.id}`">{{ role.frontendName }}</option>
          </select>
        </label>
      </div>
    </form>
  </div>
</template>

<script setup>
import {computed, inject, onMounted, reactive, ref} from "vue";
import {useStore} from "vuex";
import router from "@/router";
const store = useStore();
const user = reactive({
  id:'',
  email: '',
  firstname: '',
  lastname: '',
  phoneNumber: '',
  hourlyWage: '',
  password: '',
  extraPassword: '',
  childId: ''
})
const users = reactive({list: []})
const roles = reactive({list: []})
const selected = ref(null)
const loggedInUser = reactive({
  id: '',
  email: '',
  firstname: '',
  lastname: '',
  phoneNumber: '',
  hourlyWage: '',
});
const {userService, roleService, sessionSBService} = inject('data')
const emailValid = ref(null)
const firstnameValid = ref(null)
const lastnameValid = ref(null)
const phoneNumberValid = ref(null)
const hourlyWageValid = ref(null)
const passwordValid = ref(null)
const passwordExtraValid = ref(null)
const emailError = ref('')
const firstnameError = ref('')
const lastnameError = ref('')
const phoneNumberError = ref('')
const hourlyWageError = ref('')
const passwordError = ref('')
const passwordExtraError = ref('')
const createSuccessful = ref(null)
const token = sessionSBService.getTokenFromBrowserStorage()

const getRoles = async () => {
  try {
    const { data } = await roleService.getAllRoles()
    roles.list = data.map(r => ({...r}))
    await getAllUsers()
  } catch (e) {
    console.error(e)
  }
}

const back = () => {
  router.push("/profiel/admin-panel/gebruikers")
}

const getUserByToken = async () => {
  try {
    const {data} = await userService.getUserByToken(token)
    Object.assign(loggedInUser, { ...data });
    loggedInUser.hourlyWage = loggedInUser.hourlyWage.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    await getRoles()
  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      await router.push({path: '/inloggen'})
    }
    console.error(error)
  }
}

const createUser = async () => {
  try {
    user.hourlyWage = parseFloat(user.hourlyWage.replace(",","."))
    const { message } =  await userService.createUser(user, selected.value)
    store.commit('setSuccessMessage', message)
    await router.push({path: '/profiel/admin-panel/gebruikers'})
    createSuccessful.value = true
  } catch (e) {
    createSuccessful.value = false
    emailValid.value = false
    firstnameValid.value = false
    lastnameValid.value = false
    phoneNumberValid.value = false
    hourlyWageValid.value = false
    passwordValid.value = false
    passwordExtraValid.value = false
    store.commit('setErrorMessage', e.message)

  }
}

const getAllUsers = async () => {
  try {
    const { data } = await userService.getAllUsers();
    // Filter out the logged-in user from the list of all users
    users.list = data
        .filter(user => user.childId == null)
        .map(u => ({
          ...u,
          hourlyWageFormatted: u.hourlyWage.toLocaleString('nl-NL', { minimumFractionDigits: 2 })
        }));
  } catch (e) {
    console.error(e);
  }
};

const validateEmail = () => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (user.email === "") {
    emailError.value = "Email mag niet leeg zijn.";
    emailValid.value = false;
  } else if (emailPattern.test(user.email)) {
    emailError.value = "";
    emailValid.value = true;
  } else {
    emailError.value = "Ongeldig e-mailadres.";
    emailValid.value = false;
  }
}

const validateFirstname = () => {
  const firstnamePattern = /^[A-Z][a-zA-Z]*$/;
  if (user.firstname === "") {
    firstnameError.value = "Voornaam mag niet leeg zijn.";
    firstnameValid.value = false;
  } else if (firstnamePattern.test(user.firstname)) {
    firstnameError.value = "";
    firstnameValid.value = true;
  } else if (!/^[A-Z]/.test(user.firstname)) {
    firstnameError.value = "Voornaam moet beginnen met een hoofdletter.";
    firstnameValid.value = false;
  } else {
    firstnameError.value = "Voornaam mag alleen alfabetische tekens bevatten.";
    firstnameValid.value = false;
  }
}

const validateLastname = () => {
  const lastnamePattern = /\b[A-Z][a-zA-Z]*\b/;
  if (user.lastname === "") {
    lastnameError.value = "Achternaam mag niet leeg zijn.";
    lastnameValid.value = false;
  } else if (lastnamePattern.test(user.lastname)) {
    lastnameError.value = "";
    lastnameValid.value = true;
  } else {
    lastnameError.value = "Achternaam moet minstens één woord met een hoofdletter bevatten.";
    lastnameValid.value = false;
  }
}

const validatePhoneNumber = () => {
  const localPhoneNumberPattern = /^0[1-9][0-9]{8}$/;
  const internationalPhoneNumberPattern = /^(?:\+31|0031)[1-9][0-9]{8}$/;
  if (user.phoneNumber === "") {
    phoneNumberError.value = "Telefoonnummer mag niet leeg zijn.";
    phoneNumberValid.value = false;
  } else if (localPhoneNumberPattern.test(user.phoneNumber) || internationalPhoneNumberPattern.test(user.phoneNumber)) {
    phoneNumberError.value = "";
    phoneNumberValid.value = true;
  } else {
    phoneNumberError.value = "Ongeldig Nederlands telefoonnummer.";
    phoneNumberValid.value = false;
  }
}

const validateHourlyWage = () => {
  const hourlyWagePattern = /^[0-9]+([,.][0-9]{2})?$/;
  if (user.hourlyWage === "") {
    hourlyWageError.value = "Uurloon mag niet leeg zijn.";
    hourlyWageValid.value = false;
  } else if (hourlyWagePattern.test(user.hourlyWage)) {
    hourlyWageError.value = "";
    hourlyWageValid.value = true;
  } else {
    hourlyWageError.value = "Ongeldig uurloon. Gebruik precies twee cijfers na de komma indien aanwezig.";
    hourlyWageValid.value = false;
  }
}

const validatePassword = () => {
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  if (user.password === "") {
    passwordError.value = "Wachtwoord mag niet leeg zijn.";
    passwordValid.value = false;
  } else if (passwordPattern.test(user.password)) {
    passwordError.value = "";
    passwordValid.value = true;
  } else {
    passwordError.value = "Wachtwoord moet minimaal één hoofdletter, één kleine letter, één cijfer en één speciaal teken bevatten.";
    passwordValid.value = false;

  }
}

const validateExtraPassword = () => {
  if (user.extraPassword === "") {
    passwordExtraError.value = "Herhaal wachtwoord mag niet leeg zijn.";
    passwordExtraValid.value = false;
  } else if (user.password === user.extraPassword) {
    passwordExtraError.value = "";
    passwordExtraValid.value = true;
  } else {
    passwordExtraError.value = "Herhaal wachtwoord komt niet overeen met nieuw wachtwoord.";
    passwordExtraValid.value = false;
  }
}

const emailClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (emailValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const firstnameClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (firstnameValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const lastnameClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (lastnameValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const phoneNumberClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (phoneNumberValid.value === false ) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const hourlyWageClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (hourlyWageValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const passwordClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (passwordValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const passwordExtraClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (passwordExtraValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const buttonClass = computed(() => {
  if (emailValid.value === false || firstnameValid.value === false || lastnameValid.value === false || phoneNumberValid.value === false || hourlyWageValid.value === false || passwordValid.value === false || passwordExtraValid.value === false || !user.firstname || !user.email || !user.lastname || !user.phoneNumber || !user.hourlyWage || !user.password || !user.extraPassword || selected.value === '0' || selected.value === null || selected.value === undefined) {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 pb-2 pt-6 pr-4 pl-4 rounded border-b-4 button opacity-50 cursor-not-allowed";
  } else {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button";
  }
});


onMounted(() => {
  getUserByToken()
})
</script>