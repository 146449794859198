<template>
  <div class="w-full flex justify-center dark:bg-stone-800">
    <div class="my-10 p-7 flex shadow-own md:w-2/4 w-3/4 flex-col">
      <h1 class="text-xl dark:text-stone-100">Aanvragen Reset Code</h1>
      <form class="flex flex-col my-4 gap-4">
        <div class="flex flex-col relative input-container">
          <label class="p-2 label dark:text-stone-100" :class="{ 'active': resetPasswordCode.email }" for="email">Email</label>
          <input v-model="resetPasswordCode.email" @input="validateEmail" :class="emailClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="email"/>
          <p class="p-1 error-min-height text-red-500 text-xs italic"> {{ emailError }}</p>
        </div>
        <button type="submit" :disabled="emailValid === false || !resetPasswordCode.email" @click.prevent="sendResetCode" :class="buttonClass">Reset Code Aanvragen</button>
      </form>
    </div>
  </div>
</template>
<script setup>
import {computed, inject, reactive, ref} from "vue";
import { useStore } from 'vuex';
const resetPasswordCode = reactive({
  email: ''
})
const {sessionSBService} = inject('data')
const store = useStore();
const emailError = ref("");
const emailValid = ref(null);
const loginSuccessful = ref(null);

const sendResetCode = async () => {
  try {
    const { message } = await sessionSBService.sendResetCode(resetPasswordCode);
    store.commit('setSuccessMessage', message)
    loginSuccessful.value = true;
  } catch (error) {
    store.commit('setErrorMessage', error.message)
    loginSuccessful.value = false;
    emailValid.value = false;
  }
};

const buttonClass = computed(() => {
  if (emailValid.value === false || !resetPasswordCode.email) {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 pb-2 pt-6 pr-4 pl-4 rounded border-b-4 button opacity-50 cursor-not-allowed";
  } else {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button";
  }
});

const emailClass = computed(() => {
  if (loginSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (emailValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
});


const validateEmail = () => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (resetPasswordCode.email === "") {
    emailError.value = "Email mag niet leeg zijn.";
    emailValid.value = false;
  } else if (emailPattern.test(resetPasswordCode.email)) {
    emailError.value = "";
    emailValid.value = true;
  } else {
    emailError.value = "Ongeldig e-mailadres.";
    emailValid.value = false;
  }}



</script>


