import { createRouter, createWebHashHistory } from 'vue-router'
import Login from "@/components/Login.vue";
import Home from "@/components/home/Home.vue";
import Profile from "@/components/profile/Profile.vue";
import MyAccount from "@/components/profile/children/MyAccount.vue";
import AdminPanel from "@/components/profile/children/AdminPanel.vue";
import PasswordChange from "@/components/profile/children/PasswordChange.vue";
import ProfilePictureChange from "@/components/profile/children/ProfilePictureChange.vue";
import Users from "@/components/profile/children/adminPanelChildren/Users.vue";
import Years from "@/components/profile/children/adminPanelChildren/Years.vue";
import Months from "@/components/profile/children/adminPanelChildren/Months.vue";
import GetUser from "@/components/profile/children/adminPanelChildren/users/GetUser.vue";
import EditUser from "@/components/profile/children/adminPanelChildren/users/EditUser.vue";
import CreateUser from "@/components/profile/children/adminPanelChildren/users/CreateUser.vue";
import GetYear from "@/components/profile/children/adminPanelChildren/years/GetYear.vue";
import EditYear from "@/components/profile/children/adminPanelChildren/years/EditYear.vue";
import CreateYear from "@/components/profile/children/adminPanelChildren/years/CreateYear.vue";
import GetMonth from "@/components/profile/children/adminPanelChildren/months/GetMonth.vue";
import CreateMonth from "@/components/profile/children/adminPanelChildren/months/CreateMonth.vue";
import EditMonth from "@/components/profile/children/adminPanelChildren/months/EditMonth.vue";
import PageNotFound from "@/components/PageNotFound.vue";
import WorkingTime from "@/components/workingtimes/WorkingTime.vue";
import CreateWorkingTime from "@/components/workingtimes/CreateWorkingTime.vue";
import GetWorkingTime from "@/components/workingtimes/GetWorkingTime.vue";
import EditWorkingTime from "@/components/workingtimes/EditWorkingTime.vue";
import ResetPasswordEmail from "@/components/resetPassword/ResetPasswordEmail.vue";
import ResetPassworCode from "@/components/resetPassword/ResetPassworCode.vue";
import ResetPassword from "@/components/resetPassword/ResetPassword.vue";
import InternshipTime from "@/components/internshiptimes/InternshipTime.vue";
import InternshipTimes from "@/components/internshiptimes/components/InternshipTimes.vue";
import WorkingTimes from "@/components/workingtimes/components/WorkingTimes.vue";
import CreateInternshipTime from "@/components/internshiptimes/CreateInternshipTime.vue";
import GetInternshipTime from "@/components/internshiptimes/GetInternshipTime.vue";
import EditInternshipTime from "@/components/internshiptimes/EditInternshipTime.vue";
import Settings from "@/components/profile/children/Settings.vue";
import Internship from "@/components/internships/Internship.vue";
import Internships from "@/components/internships/components/Internships.vue";
import GetInternship from "@/components/internships/GetInternship.vue";
import CreateInternship from "@/components/internships/CreateInternship.vue";
import EditInternship from "@/components/internships/EditInternship.vue";
import WorkingTimeParent from "@/components/workingtimes/parent/WorkingTimeParent.vue";
import WorkingTimesParent from "@/components/workingtimes/parent/components/WorkingTimesParent.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: "Home",
      description: "Home Pagina",
      keywords: 'Rick Veerman'
    },
    component: Home
  },
  {
    path: '/inloggen',
    name: 'login',
    meta: {
      title: "Login",
      description: "Login Pagina",
      keywords: 'Rick Veerman'
    },
    component: Login
  },
  {
    path: '/wachtwoord-resetten',
    name: 'wachtwoord-resetten',
    meta: {
      title: "Wachtwoord Resetten",
      description: "Wachtwoord Resetten Pagina",
      keywords: 'Rick Veerman'
    },
    component: ResetPassword
  },
  {
    path: '/wachtwoord-resetten/verzenden',
    name: 'wachtwoord-resetten-send',
    meta: {
      title: "Wachtwoord Resetten Verzenden",
      description: "Wachtwoord Resetten Verzenden Pagina",
      keywords: 'Rick Veerman'
    },
    component: ResetPasswordEmail
  },
  {
    path: '/wachtwoord-resetten/check/:email',
    name: 'wachtwoord-resetten-check',
    meta: {
      title: "Wachtwoord Resetten Check",
      description: "Wachtwoord Resetten Check Pagina",
      keywords: 'Rick Veerman'
    },
    component: ResetPassworCode
  },
  {
    path: '/werktijden',
    name: 'workingTime',
    component: WorkingTime,
    meta: {
      title: "Werktijden",
      description: "Werktijden Pagina",
      keywords: 'Rick Veerman'
    },
    children: [
      {
        path: ':yearId/:monthId',
        name: 'workingTimes',
        component: WorkingTimes
      }
    ]
  },
  {
    path: '/werktijden/ouder',
    name: 'workingTimeParent',
    component: WorkingTimeParent,
    meta: {
      title: "Werktijden",
      description: "Werktijden Pagina",
      keywords: 'Rick Veerman'
    },
    children: [
      {
        path: ':yearId/:monthId',
        name: 'workingTimesParent',
        component: WorkingTimesParent
      }
    ]
  },
  {
    path: '/stages',
    name: 'internship',
    component: Internship,
    meta: {
      title: "Stages",
      description: "Stages Pagina",
      keywords: 'Rick Veerman'
    },
    children: [
      {
        path: '',
        name: 'internships',
        component: Internships
      }
    ]
  },
  {
    path: `/stage/aanmaken`,
    name: 'createInternship',
    meta: {
      title: "Stage Aanmaken",
      description: "Stage Aanmaken Pagina",
      keywords: 'Rick Veerman'
    },
    component: CreateInternship,
  },
  {
    path: '/stage/:id',
    name: 'getInternship',
    meta: {
      title: "Stage",
      description: "Stage Pagina",
      keywords: 'Rick Veerman'
    },
    component: GetInternship,
  },
  {
    path: '/stage/wijzigen/:id',
    name: 'editInternshipTime',
    meta: {
      title: "Stage Wijzigen",
      description: "Stage Wijzigen Pagina",
      keywords: 'Rick Veerman'
    },
    component: EditInternship,
  },
  {
    path: '/stagetijden',
    name: 'internshipTime',
    component: InternshipTime,
    meta: {
      title: "Stagetijden",
      description: "Stagetijden Pagina",
      keywords: 'Rick Veerman'
    },
    children: [
        {
      path: ':internshipId/:pageNo',
      name: 'internshipTimes',
      component: InternshipTimes
    }
    ]
  },
  {
    path: `/stagetijd/aanmaken/:internshipId/:pageNo`,
    name: 'createInternshipTime',
    meta: {
      title: "Stagetijd Aanmaken",
      description: "Stagetijd Aanmaken Pagina",
      keywords: 'Rick Veerman'
    },
    component: CreateInternshipTime,
  },
  {
    path: '/stagetijd/:id/:internshipId/:pageNo',
    name: 'getInternshipTime',
    meta: {
      title: "Stagetijd",
      description: "Stagetijd Pagina",
      keywords: 'Rick Veerman'
    },
    component: GetInternshipTime,
  },
  {
    path: '/stagetijd/wijzigen/:id/::internshipId/:pageNo',
    name: 'editInternshipTime',
    meta: {
      title: "Stagetijd Wijzigen",
      description: "Stagetijd Wijzigen Pagina",
      keywords: 'Rick Veerman'
    },
    component: EditInternshipTime,
  },
  {
    path: '/werktijd/aanmaken/:yearId/:monthId',
    name: 'createWorkingTimes',
    meta: {
      title: "Werktijd Aanmaken",
      description: "Werktijd Aanmaken Pagina",
      keywords: 'Rick Veerman'
    },
    component: CreateWorkingTime
  },
  {
    path: '/werktijd/:id',
    name: 'getWorkingTimes',
    meta: {
      title: "Werktijden",
      description: "Werktijden Pagina",
      keywords: 'Rick Veerman'
    },
    component: GetWorkingTime
  },
  {
    path: '/werktijd/wijzigen/:id',
    name: 'editWorkingTimes',
    meta: {
      title: "Werktijden Wijzigen",
      description: "Werktijden Wijzigen Pagina",
      keywords: 'Rick Veerman'
    },
    component: EditWorkingTime
  },
  {
    path: '/profiel',
    name: 'profiel',
    component: Profile,
    children:[
      {
      path: 'mijn-account',
      name: 'Mijn Account',
        meta: {
          title: "Profiel - Mijn Account",
          description: "Profiel - Mijn Account Pagina",
          keywords: 'Rick Veerman'
        },
      component: MyAccount
      },
      {
        path: 'admin-panel',
        name: 'Admin Panel',
        component: AdminPanel,
        children: [
          {
            path: 'gebruikers',
            name: 'users',
            meta: {
              title: "Profiel - Admin Panel - Gebruikers",
              description: "Profiel - Admin Panel - Gebruikers Pagina",
              keywords: 'Rick Veerman'
            },
            component: Users
          },
          {
            path: 'gebruiker/:id',
            name: 'getUser',
            meta: {
              title: "Profiel - Admin Panel - Gebruiker",
              description: "Profiel - Admin Panel - Gebruiker Pagina",
              keywords: 'Rick Veerman'
            },
            component: GetUser
          },
          {
            path: 'gebruiker/wijzigen/:id',
            name: 'editUser',
            meta: {
              title: "Profiel - Admin Panel - Gebruiker Wijzigen",
              description: "Profiel - Admin Panel - Gebruiker Wijzigen Pagina",
              keywords: 'Rick Veerman'
            },
            component: EditUser
          },
          {
            path: 'gebruiker/aanmaken',
            name: 'createUser',
            meta: {
              title: "Profiel - Admin Panel - Gebruiker Aanmaken",
              description: "Profiel - Admin Panel - Gebruiker Aanmaken Pagina",
              keywords: 'Rick Veerman'
            },
            component: CreateUser
          },
          {
            path: 'jaren',
            name: 'years',
            meta: {
              title: "Profiel - Admin Panel - Jaren",
              description: "Profiel - Admin Panel - Jaren Pagina",
              keywords: 'Rick Veerman'
            },
            component: Years
          },
          {
            path: 'jaar/:id',
            name: 'getYear',
            meta: {
              title: "Profiel - Admin Panel - Jaar",
              description: "Profiel - Admin Panel - Jaar Pagina",
              keywords: 'Rick Veerman'
            },
            component: GetYear
          },
          {
            path: 'jaar/wijzigen/:id',
            name: 'editYear',
            meta: {
              title: "Profiel - Admin Panel - Jaar Wijzigen",
              description: "Profiel - Admin Panel - Jaar Wijzigen Pagina",
              keywords: 'Rick Veerman'
            },
            component: EditYear
          },
          {
            path: 'jaar/aanmaken',
            name: 'createYear',
            meta: {
              title: "Profiel - Admin Panel - Jaar Aanmaken",
              description: "SProfiel - Admin Panel - Jaar Aanmaken Pagina",
              keywords: 'Rick Veerman'
            },
            component: CreateYear
          },
          {
            path: 'maanden',
            name: 'months',
            meta: {
              title: "Profiel - Admin Panel - Maanden",
              description: "Profiel - Admin Panel - Maanden Pagina",
              keywords: 'Rick Veerman'
            },
            component: Months
          },
          {
            path: 'maand/:id',
            name: 'getMonth',
            meta: {
              title: "Profiel - Admin Panel - Maand",
              description: "Profiel - Admin Panel - Maand Pagina",
              keywords: 'Rick Veerman'
            },
            component: GetMonth
          },
          {
            path: 'maand/aanmaken',
            name: 'createMonth',
            meta: {
              title: "Profiel - Admin Panel - Maand Aanmaken",
              description: "Profiel - Admin Panel - Maand Aanmaken Pagina",
              keywords: 'Rick Veerman'
            },
            component: CreateMonth
          },
          {
            path: 'maand/wijzigen/:id',
            name: 'editMonth',
            meta: {
              title: "Profiel - Admin Panel - Maand Wijzigen",
              description: "Profiel - Admin Panel - Maand Wijzigen Pagina",
              keywords: 'Rick Veerman'
            },
            component: EditMonth
          }
        ]
      },
      {
        path: 'wachtwoord-wijzigen',
        name: 'Wachtwoord Wijzigen',
        meta: {
          title: "Profiel - Wachtwoord Wijzigen",
          description: "Profiel - Wachtwoord Wijzigen Pagina",
          keywords: 'Rick Veerman'
        },
        component: PasswordChange
      },
      {
        path: 'instellingen',
        name: 'Instellingen',
        meta: {
          title: "Profiel - Instellingen",
          description: "Profiel - Instellingen Pagina",
          keywords: 'Rick Veerman'
        },
        component: Settings
      },
      {
        path: 'profiel-foto-wijzigen',
        name: 'Profiel Foto Wijzigen',
        meta: {
          title: "Profiel - Profiel Foto Wijzigen",
          description: "Profiel - Profiel Foto Wijzigen Pagina",
          keywords: 'Rick Veerman'
        },
        component: ProfilePictureChange
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
  },
  {
    path: '/:pathMatch(.*)*',
    component: PageNotFound
  }
]

const router = createRouter({
  linkActiveClass: '!border-b-orange-600',
  linkExactActiveClass: '!border-b-orange-600',
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`
  document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description);

  next()
})

export default router
