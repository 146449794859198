import CustomError from "../../CustomError";

export class SessionSbService {
  BROWSER_STORAGE_ITEM_NAME;
  RESOURCES_URL;

  constructor (resourceUrl, browserStorageItemName) {
    this.BROWSER_STORAGE_ITEM_NAME = browserStorageItemName
    this.RESOURCES_URL = resourceUrl
    this.getTokenFromBrowserStorage()
  }

  async fetchJson (url, options = null) {
    try {
      const response = await fetch(url, options)
      if (response.ok) {
        return await response.json()
      }
    } catch (error) {
      const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
      throw customError.toJSON()    }
  }

  async login (user) {
    try {

      const response = await this.fetchJson(`${this.RESOURCES_URL}` + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      })

      this.saveTokenIntoBrowserStorage(response.data.accessToken, user.email)
      return response
    } catch (error) {
      // Check if the error has a response body with an error message

      const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
      throw customError.toJSON()    }
  }

  async logout() {
    try {
      const response = await this.fetchJson(`${this.RESOURCES_URL}/logout`, {
        method: 'POST',
      })
      this.deleteTokenFromBrowserStorage()
      return response

    } catch (error) {
      const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
      throw customError.toJSON()
    }
  }

  async sendResetCode(resetPasswordCode) {
    try {
      return await this.fetchJson(`${this.RESOURCES_URL}/reset-password/code/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(resetPasswordCode)
      })

    } catch (error) {
      const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
      throw customError.toJSON()
    }
  }

  async checkResetCode(resetPasswordCode) {
    try {
      return await this.fetchJson(`${this.RESOURCES_URL}/reset-password/code/check`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(resetPasswordCode)
      })

    } catch (error) {
      const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
      throw customError.toJSON()
    }
  }

  async resetPassword(resetPassword) {
    try {
      return await this.fetchJson(`${this.RESOURCES_URL}/reset-password`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(resetPassword)
      })

    } catch (error) {
      const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
      throw customError.toJSON()
    }
  }


  saveTokenIntoBrowserStorage (token, user) {
    if (token !== null && user !== null) {
      // Save token to session storage
      window.sessionStorage.setItem(this.BROWSER_STORAGE_ITEM_NAME,`Bearer ${token}`)

      // Check if the token is already in local storage
      if (this.getTokenFromBrowserStorage() !== this.BROWSER_STORAGE_ITEM_NAME) {
        // Save token to local storage
        window.localStorage.setItem(this.BROWSER_STORAGE_ITEM_NAME, `Bearer ${token}`)
      }
    } else {
      // If either token or user is null, remove from both storages
      window.sessionStorage.removeItem(this.BROWSER_STORAGE_ITEM_NAME)
      window.localStorage.removeItem(this.BROWSER_STORAGE_ITEM_NAME)
    }
  }

  deleteTokenFromBrowserStorage () {
    window.sessionStorage.removeItem(this.BROWSER_STORAGE_ITEM_NAME)
    window.localStorage.removeItem(this.BROWSER_STORAGE_ITEM_NAME)
  }

  isAuthenticated () {
    // Check if the token exists in session storage or local storage
    return !!this.getTokenFromBrowserStorage()
  }

  getTokenFromBrowserStorage () {
    return window.sessionStorage.getItem(this.BROWSER_STORAGE_ITEM_NAME) || window.localStorage.getItem(this.BROWSER_STORAGE_ITEM_NAME)
  }

}
