<template>
  <button @click.prevent="back" class="mb-2 dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button">Terug</button>
  <div class="flex gap-4 flex-col md:flex-row">
    <div class="flex flex-col gap-2 w-52 space-y-4">
      <div>
        <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Jaar</h1>
        <p class="text-gray-600 dark:text-gray-400">{{year.year}}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {inject, onMounted, reactive, ref} from "vue";
const router = useRouter();
const route = useRoute();
const yearId = ref('')
const {yearService} = inject('data')
const year = reactive( {
  id: '',
  year: '',
})
const getYearById = async () => {
  try {
    const {data} = await yearService.getYearById(yearId.value)
    Object.assign(year, { ...data });
  } catch (error) {
    console.error(error)
  }
}



const back = () => {
  router.push("/profiel/admin-panel/jaren")
}



onMounted(() => {
  yearId.value = route.params.id
  getYearById()

})

</script>