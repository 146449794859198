<template>
  <div class="w-full flex justify-center dark:bg-stone-800">
    <div class="my-20 p-7 flex shadow-own w-11/12 gap-2 md:w-3/4 lg:w-7/12 flex-col">
      <div class="flex gap-8 items-center justify-between " >
        <div class="flex justify-between items-center w-3/12  gap-4 ">
          <h1 class="text-xl dark:text-stone-100 ">Profiel</h1>
          <button title="dropdown-button" @click.prevent="toggleRouters" type="button" class="text- flex w-12 lg:hidden h-12 border-transition background-blur shadow-own rounded border-b-4 pb-2 pt-4 pr-4 pl-4  dark:border-b-stone-600 font-bold hover:border-b-orange-600" >
            <svg class="w-5 h-5 dark:text-stone-100" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
          </button>
        </div>
        <div class="flex  ">
          <h1 class="text-xl dark:text-stone-100">{{ routerName}}</h1>
        </div>
      </div>
      <div class="flex gap-3">
          <ul v-if="toggleRouters" class="w-full lg:w-auto lg:flex flex-col gap-3" :class="showRouters ? 'flex' : 'hidden'">
            <li class="sidebar" @click.prevent="closeRouter" ><router-link class="dark:text-stone-100 dark:border-b-stone-600 text-sm border-b-4 pb-2 pt-4 pr-4 pl-4 block h-full w-full border-transition button rounded hover:border-b-orange-600 border-b-stone-200" to="/profiel/mijn-account">Mijn Account</router-link></li>
            <li class="sidebar" @click.prevent="closeRouter"><router-link  class="dark:text-stone-100 dark:border-b-stone-600 text-sm border-b-4 pb-2 pt-4 pr-4 pl-4 block h-full w-full border-transition button rounded hover:border-b-orange-600 border-b-stone-200" to="/profiel/profiel-foto-wijzigen">Profiel Foto wijzigen</router-link></li>
            <li class="sidebar" @click.prevent="closeRouter"><router-link  class="dark:text-stone-100 dark:border-b-stone-600 text-sm border-b-4 pb-2 pt-4 pr-4 pl-4 block h-full w-full border-transition button rounded hover:border-b-orange-600 border-b-stone-200" to="/profiel/wachtwoord-wijzigen">Wachtwoord Wijzigen</router-link></li>
            <li class="sidebar" @click.prevent="closeRouter"><router-link  class="dark:text-stone-100 dark:border-b-stone-600 text-sm border-b-4 pb-2 pt-4 pr-4 pl-4 block h-full w-full border-transition button rounded hover:border-b-orange-600 border-b-stone-200" to="/profiel/instellingen">Instellingen</router-link></li>
            <li v-if="isAdmin" class="sidebar" @click.prevent="closeRouter"><router-link  class="dark:text-stone-100 dark:border-b-stone-600 text-sm border-b-4 pb-2 pt-4 pr-4 pl-4 block h-full w-full border-transition button rounded hover:border-b-orange-600 border-b-stone-200" :to="adminPanelRoute">Admin Panel</router-link></li>
            <li class="sidebar"><a @click.prevent="logout" class="cursor-pointer dark:border-b-stone-600 dark:text-stone-100 text-sm border-b-4 pb-2 pt-4 pr-4 pl-4 block h-full w-full border-transition button rounded hover:border-b-orange-600 border-b-stone-200">Uitloggen</a></li>
          </ul>
        <div v-if="showContent" class="w-full">
          <router-view :showRouters="showRouters"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";
import {computed, inject, onMounted, reactive, ref, watch} from "vue";
import {User} from "@/models/User";
const {sessionSBService, userService} = inject('data')
import {useStore} from "vuex";
import useEventsBus from "../../../eventBus";
import {useRoute} from "vue-router";
const store = useStore();
const {emit} = useEventsBus();
const adminPanelRoute = ref("/profiel/admin-panel/gebruikers")
const user = reactive(new User())
const showRouters = ref(false)
const token = sessionSBService.getTokenFromBrowserStorage()
const showContent = ref(true)
const isAdmin = ref(false)
const route =useRoute()
const getUserByToken = async () => {
  try {
    const {data} = await userService.getUserByToken(token)
    Object.assign(user, new User(data.id, data.email, data.firstname, data.lastname, data.phoneNumber, data.hourlyWage, data.profilePicture, data.roles ))
    data.roles.forEach(role => {
      if (role.frontendName === 'Admin') {
        isAdmin.value = true;
      }
    });
  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit('handleLogout')
      await router.push({path: '/inloggen'})
    }
    console.error(error)
  }
}

const routerName = computed(() => {
  const routeName = router.currentRoute.value.name;
  if (routeName === "users" || routeName === "years" || routeName === "months" || routeName === "getUser" || routeName === "createUser" || routeName === "editUser" || routeName === "getYear" || routeName === "createYear" || routeName === "editYear" || routeName === "getMonth" || routeName === "editMonth" || routeName === "createMonth") {
    return 'Admin Panel';
  } else {
    return routeName;
  }
});

const toggleRouters = () => {
  showRouters.value = !showRouters.value
  showContent.value = !showContent.value
}

const closeRouter = () => {
  showRouters.value = false
  showContent.value = true
}

const logout = async () => {
  try {
    const response = await sessionSBService.logout()
    await router.push({path: '/inloggen'})
    store.commit('setSuccessMessage', response.message)
    emit('handleLogout')

  } catch(error) {
    console.error(error)
  }
}

const routerCheck = () => {
  if (router.currentRoute.value.fullPath === "/profiel") {
    router.push("/profiel/mijn-account")
  }

}

watch(() => route.path, async (newValue) => {
  if (newValue.includes("admin-panel")) {
    adminPanelRoute.value = newValue
  }
});


onMounted(() => {
  getUserByToken()
  routerCheck()
});
</script>