<template>
  <div class="dark:bg-stone-800 shadow-own">
    <div class="relative overflow-hidden">
      <div :style="sliderStyle" class="flex transition-transform duration-300 ease-in-out dark:text-stone-100">
        <div v-for="month in months.list" :key="month.id" class="flex-none w-full text-center text-lg p-3">
          {{ month.month }}
        </div>
      </div>
      <button @click="prevMonth" class="absolute top-1/2 transform -translate-y-1/2 left-0 p-3 bg-transparent border-none outline-none cursor-pointer text-xl text-stone-900 dark:text-stone-100 dark:hover:text-stone-300 hover:text-stone-700 transition-opacity duration-300">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
          <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"></path>
        </svg>
      </button>
      <button @click="nextMonth" class="absolute top-1/2 transform -translate-y-1/2 right-0 p-3 bg-transparent border-none outline-none cursor-pointer text-xl text-stone-900 dark:text-stone-100 dark:hover:text-stone-300 hover:text-stone-700 transition-opacity duration-300">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import useEventsBus from "../../../../../eventBus";
const route = useRoute();
const router = useRouter();
const selectedMonth = ref(route.params.monthId);
const months = reactive({ list: [] });
const { monthService } = inject('data');
const { emit } = useEventsBus();
const { bus } = useEventsBus();
const selectedYear = ref(route.params.yearId);

const getMonths = async () => {
  try {
    const { data } = await monthService.getAllMonthsByYearId(selectedYear.value);
    months.list = data.map(m => ({ ...m }));
  } catch (e) {
    console.error(e);
  }
};

const sliderStyle = computed(() => {
  const monthIndex = months.list.findIndex(month => month.id.toString() === selectedMonth.value);
  let translateXValue = monthIndex * -100;
  if (monthIndex === -1) {
    translateXValue = 0;
  }

  return {
    transform: `translateX(${translateXValue}%)`,
  };
});

const prevMonth = () => {
  const currentIndex = months.list.findIndex(month => month.id.toString() === selectedMonth.value);
  if (currentIndex > 0) {
    selectedMonth.value = months.list[currentIndex - 1].id.toString();
  }
  router.push(`/werktijden/ouder/${selectedYear.value}/${selectedMonth.value}`);
  emit('updateSelectMonth', selectedMonth.value);
};

const nextMonth = () => {
  const currentIndex = months.list.findIndex(month => month.id.toString() === selectedMonth.value);
  if (currentIndex < months.list.length - 1) {
    selectedMonth.value = months.list[currentIndex + 1].id.toString();
  }
  router.push(`/werktijden/ouder/${selectedYear.value}/${selectedMonth.value}`);
  emit('updateSelectMonth', selectedMonth.value);
};

watch(
    () => bus.value.get('updateSelectYear'),
    async (newValue) => {
      selectedYear.value = newValue;
      await getMonths();
      // Check if the previously selected month exists in the new list of months
      const index = months.list.findIndex((month) => month.id.toString() === selectedMonth.value);
      if (index === -1) {
        // If the previously selected month does not exist in the new list,
        // set the selected month to the first month of the new list
        selectedMonth.value = months.list.length > 0 ? months.list[0].id.toString() : null;
      }
      await router.push(`/werktijden/ouder/${selectedYear.value}/${selectedMonth.value}`);
      emit('updateSelectMonth', selectedMonth.value);
    }
);



onMounted(() => {
  getMonths();
});
</script>
