<template>
  <NavBar></NavBar>
  <router-view></router-view>
  <SuccessNotification ></SuccessNotification>
  <FooterApp></FooterApp>
</template>

<script setup>

import NavBar from "@/components/navbar/NavBar.vue";
import {onUnmounted, provide, reactive, shallowReactive} from "vue";
import {SessionSbService} from "@/services/SessionSbService";
import CONFIG from "../app-config";
import {FetchInterceptor} from "@/services/FetchInterceptor";
import {UserService} from "@/services/UserService";
import SuccessNotification from "@/components/Notification.vue";
import {RoleService} from "@/services/RoleService";
import {YearService} from "@/services/YearService";
import {MonthService} from "@/services/MonthService";
import FooterApp from "@/components/FooterApp.vue";
import {WorkingTimeService} from "@/services/WorkingTimeService";
import {InternshipTimesService} from "@/services/InternshipTimesService";
import {InternshipsService} from "@/services/InternshipsService";

const theSessionSbService = shallowReactive(new SessionSbService(CONFIG.BACKEND_URL + '/auth', CONFIG.JWT_STORAGE_ITEM))
const theFetchInterceptor = new FetchInterceptor(theSessionSbService);

provide('data', {
  sessionSBService : shallowReactive(new SessionSbService(CONFIG.BACKEND_URL + '/auth', CONFIG.JWT_STORAGE_ITEM)),
  userService: reactive(new UserService(CONFIG.BACKEND_URL + '/users')),
  roleService: reactive(new RoleService(CONFIG.BACKEND_URL + '/roles')),
  yearService: reactive(new YearService(CONFIG.BACKEND_URL + '/years')),
  monthService: reactive(new MonthService(CONFIG.BACKEND_URL + '/months')),
  workingTimeService: reactive(new WorkingTimeService(CONFIG.BACKEND_URL + '/workingtimes')),
  internshipTimeService: reactive(new InternshipTimesService(CONFIG.BACKEND_URL + '/internshiptimes')),
  internshipService: reactive(new InternshipsService(CONFIG.BACKEND_URL + '/internships')),
})

onUnmounted(() => {
  theFetchInterceptor.unregister()
})
</script>
