import CustomError from "../../CustomError";

export class WorkingTimeService {
    url
    constructor(url) {
        this.url = url;
    }

    async fetchJson(url, options) {
        try {
            const response = await fetch(url, options);

            if (response.ok) {

                return await response.json();
            }
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async getAllWorkingTimesBy(monthId, userId) {
        try {
            return await this.fetchJson(`${this.url}/${monthId}/${userId}`);
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async getAllChildWorkingTimesByMonthIdAndParentId(monthId, parentId) {
        try {
            return await this.fetchJson(`${this.url}/child/${monthId}/${parentId}`);
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async getWorkingTimeById(id) {
        try {
            return await this.fetchJson(`${this.url}/${id}`);
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async createWorkingTime(workingTime, monthId, userId) {
        try {
            return await this.fetchJson(`${this.url}/${monthId}/${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(workingTime),
            });
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async updateWorkingTimes(workingTime) {
        try {
            return await this.fetchJson(`${this.url}/${workingTime.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(workingTime),
            });
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async removeWorkingTime(workingTimeId) {
        try {
            return await this.fetchJson(`${this.url}/${workingTimeId}`, {
                method: 'DELETE'
            })
        } catch (e) {
            const customError = new CustomError(e.message, e.timestamp, e.details, e.status)
            throw customError.toJSON()
        }
    }

}