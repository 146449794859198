class CustomError extends Error {
  constructor(message, timestamp, details, status) {
    super(message);
    this.timestamp = timestamp;
    this.details = details;
    this.status = status
  }

  toJSON() {
    return {
      message: this.message,
      timestamp: this.timestamp,
      details: this.details,
      status: this.status
    };
  }


}

export default CustomError;
