<template>
  <div>
    <label for="data"  class="dark:bg-stone-800 dark:text-stone-100 ">
      <select v-model="selectedYear" class="w-36 select-none focus:outline-none dark:bg-stone-800 dark:text-stone-100 input-field focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent hover:border-b-orange-600  border-t hover:border-t-orange-600 border-l hover:border-l-orange-600  border-r hover:border-r-orange-600" name="data" id="data">
        <option disabled value="0">Selecteer een jaar</option>
        <option v-for="year in years.list" :key="year.id" :value="year.id">{{ year.year }}</option>
      </select>
    </label>
  </div>
</template>

<script setup>
import {inject, onMounted, reactive, ref, watch} from 'vue';
import {useRoute, useRouter} from "vue-router";
import useEventsBus from "../../../../../eventBus";
const route = useRoute();
const router = useRouter();
const {yearService} = inject('data')
const { emit } = useEventsBus();

const years = reactive({list: []})

const selectedYear = ref(route.params.yearId)

const getYears = async () => {
  try {
    const {data} = await yearService.getAllYears()
    years.list = data.map(u => ({...u}));
  } catch (e) {
    console.error(e)
  }
}




watch(selectedYear, async(newId) => {
  await router.push(`/werktijden/ouder/${newId}/${route.params.monthId}`)
  emit('updateSelectYear', newId)
});

onMounted(() => {
  getYears()
})
</script>
