<template>
  <div class="w-full flex justify-center dark:bg-stone-800">
    <div class="my-10 p-7 flex shadow-own md:w-2/4 w-3/4 flex-col">
      <h1 class="text-xl dark:text-stone-100">Reset Wachtwoord</h1>
      <form class="flex flex-col my-4 gap-4">
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': resetPassword.password }" for="password">Wachtwoord</label>
        <input v-model="resetPassword.newPassword" @input="validatePassword" :class="passwordClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="password" id="password"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ passwordError}}</p>
      </div>
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': resetPassword.extraPassword }" for="extraPassword">Herhaal Wachtwoord</label>
        <input v-model="resetPassword.extraNewPassword" @input="validateExtraPassword" :class="passwordExtraClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="password" id="extraPassword"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ passwordExtraError}}</p>
      </div>
        <button type="submit" :disabled="passwordValid === false || passwordExtraValid === false || !resetPassword.newPassword || !resetPassword.extraNewPassword" @click.prevent="resetPasswordCode" :class="buttonClass">Reset Wachtwoord</button>
    </form>
    </div>
  </div>
</template>
<script setup>
import {computed, inject, onMounted, reactive, ref, watch} from "vue";
import router from "@/router";
import {useStore} from "vuex";
import useEventsBus from "../../../eventBus";

const passwordError = ref('')
const passwordExtraError = ref('')
const passwordValid = ref('')
const passwordExtraValid = ref('')
const {sessionSBService} = inject('data')
const store = useStore();
const createSuccessful = ref(null)
const { bus } = useEventsBus();
const resetPassword = reactive({
  email: '',
  code: '',
  newPassword: '',
  extraNewPassword: ''
})

const resetPasswordCode = async () => {
  try {
    const { message } =  await sessionSBService.resetPassword(resetPassword)
    store.commit('setSuccessMessage', message)
    await router.push({path: '/inloggen'})
    createSuccessful.value = true
  } catch (e) {
    createSuccessful.value = false
    passwordValid.value = false
    passwordExtraValid.value = false
    store.commit('setErrorMessage', e.message)

  }
}

watch(() => bus.value.get('sendCodeEmail'), async (newValue) => {
 resetPassword.email = newValue.email;
 resetPassword.code = newValue.code;
});

const passwordClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (passwordValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const passwordExtraClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (passwordExtraValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const buttonClass = computed(() => {
  if (passwordValid.value === false || passwordExtraValid.value === false || !resetPassword.newPassword || !resetPassword.extraNewPassword) {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 pb-2 pt-6 pr-4 pl-4 rounded border-b-4 button opacity-50 cursor-not-allowed";
  } else {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button";
  }
});

const validatePassword = () => {
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  if (resetPassword.newPassword === "") {
    passwordError.value = "Wachtwoord mag niet leeg zijn.";
    passwordValid.value = false;
  } else if (passwordPattern.test(resetPassword.newPassword)) {
    passwordError.value = "";
    passwordValid.value = true;
  } else {
    passwordError.value = "Wachtwoord moet minimaal één hoofdletter, één kleine letter, één cijfer en één speciaal teken bevatten.";
    passwordValid.value = false;

  }
}

const validateExtraPassword = () => {
  if (resetPassword.extraNewPassword === "") {
    passwordExtraError.value = "Herhaal wachtwoord mag niet leeg zijn.";
    passwordExtraValid.value = false;
  } else if (resetPassword.extraNewPassword === resetPassword.extraNewPassword) {
    passwordExtraError.value = "";
    passwordExtraValid.value = true;
  } else {
    passwordExtraError.value = "Herhaal wachtwoord komt niet overeen met nieuw wachtwoord.";
    passwordExtraValid.value = false;
  }
}

onMounted(() => {
  const sendCodeEmail = bus.value.get('sendCodeEmail')
  resetPassword.code = sendCodeEmail[0].code
  resetPassword.email = sendCodeEmail[0].email

})
</script>

