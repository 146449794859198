<template>
  <div>
    <form class="flex flex-col my-4 gap-2">
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': user.oldPassword }" for="oldPassword">Oud Wachtwoord</label>
        <input v-model="user.oldPassword" @input="resetOldPasswordStatus" :class="passwordClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="password" id="oldPassword"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic"></p>
      </div>
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': user.newPassword }" for="newPassword">Nieuw Wachtwoord</label>
        <input v-model="user.newPassword" @input="validateNewPassword" :class="newPasswordClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="password" id="newPassword"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ newPasswordError}}</p>
      </div>
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': user.newPasswordExtra }" for="newPasswordExtra">Herhaal Nieuw Wachtwoord</label>
        <input v-model="user.newPasswordExtra" @input="validateNewPasswordExtra" :class="newPasswordExtraClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="password" id="newPasswordExtra"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ newPasswordExtraError}}</p>
      </div>
      <button type="submit" :disabled="newPasswordValid === false || newPasswordExtraClass === false || !user.newPassword || !user.newPasswordExtra" @click.prevent="updatePassword" :class="buttonClass">Wijzigen</button>
    </form>
  </div>
</template>

<script setup>
import {computed, inject, reactive, ref} from "vue";
import {useStore} from "vuex";
import router from "@/router";
import useEventsBus from "../../../../eventBus";
const user = reactive({
  id:'',
  oldPassword: '',
  newPassword: '',
  newPasswordExtra: ''
})
const newPasswordValid = ref(null)
const newPasswordError = ref('')
const newPasswordExtraValid = ref(null)
const newPasswordExtraError = ref('')
const { emit } = useEventsBus();
const updateSuccessful = ref(null)
const store = useStore();
const {sessionSBService, userService} = inject('data')
const token = sessionSBService.getTokenFromBrowserStorage()

const buttonClass = computed(() => {
  if (newPasswordValid.value === false || newPasswordExtraValid.value === false || !user.oldPassword || !user.newPassword || !user.newPasswordExtra) {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 pb-2 pt-6 pr-4 pl-4 rounded border-b-4 button opacity-50 cursor-not-allowed";
  } else {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button";
  }
});

const updatePassword = async () => {
  try {
    const { data } = await userService.getUserByToken(token)
    Object.assign(user, {...data})
    const { message } = await userService.updatePassword(user)
    store.commit('setSuccessMessage', message)
    updateSuccessful.value = true
    await router.push({path: '/profiel/mijn-account'})
  } catch (e) {
    if (e.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit('handleLogout')

      await router.push({path: '/inloggen'})
    }
    updateSuccessful.value = false
    newPasswordValid.value = false
    newPasswordExtraValid.value = false
    store.commit('setErrorMessage', e.message)
  }
}

const validateNewPassword = () => {
  // Regular expression to check for at least one uppercase letter, one lowercase letter, one digit, and one special character
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  if (user.newPassword === "") {
    newPasswordError.value = "Wachtwoord mag niet leeg zijn.";
    newPasswordValid.value = false;
  } else if (passwordPattern.test(user.newPassword)) {
    newPasswordError.value = "";
    newPasswordValid.value = true;
  } else {
    newPasswordError.value = "Wachtwoord moet minimaal één hoofdletter, één kleine letter, één cijfer en één speciaal teken bevatten.";
    newPasswordValid.value = false;

  }
};

const resetOldPasswordStatus = () => {
  updateSuccessful.value = null;
};

const validateNewPasswordExtra = () => {
  if (user.newPasswordExtra === "") {
    newPasswordExtraError.value = "Herhaal wachtwoord mag niet leeg zijn.";
    newPasswordExtraValid.value = false;
  } else if (user.newPassword === user.newPasswordExtra) {
    newPasswordExtraError.value = "";
    newPasswordExtraValid.value = true;
  } else {
    newPasswordExtraError.value = "Herhaal wachtwoord komt niet overeen met nieuw wachtwoord.";
    newPasswordExtraValid.value = false;
  }
};

const newPasswordClass = computed(() => {
  if (updateSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (newPasswordValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
});

const passwordClass = computed(() => {
  if (updateSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (updateSuccessful.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
});

const newPasswordExtraClass = computed(() => {
  if (updateSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (newPasswordExtraValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
});



</script>