<template>
  <div class="flex ">
    <label for="data"  class="dark:bg-stone-800 dark:text-stone-100">
      <select v-model="selectedInternship" class="w-36 select-none focus:outline-none dark:bg-stone-800 dark:text-stone-100 input-field focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent hover:border-b-orange-600  border-t hover:border-t-orange-600 border-l hover:border-l-orange-600  border-r hover:border-r-orange-600" name="data" id="data">
        <option disabled value="0">Selecteer een stage</option>
        <option v-for="internship in internships.list" :key="internship.id" :value="internship.id">{{ internship.company }}</option>
      </select>
    </label>
  </div>
</template>

<script setup>
import {inject, onMounted, reactive, ref, watch} from 'vue';
import {useRoute, useRouter} from "vue-router";
import useEventsBus from "../../../../eventBus";
const route = useRoute();
const router = useRouter();
const {internshipService, sessionSBService, userService, internshipTimeService} = inject('data')
const { emit } = useEventsBus();
const token = sessionSBService.getTokenFromBrowserStorage();

const user = reactive({
  id: ''
});


const internships = reactive({list: []})

const selectedInternship = ref(null)

const getInternships = async () => {
  try {

    const {data} = await internshipService.getInternshipsByUser(user)
    internships.list = data.map(i => ({...i}));
    if (route.params.internshipId) {
      selectedInternship.value = route.params.internshipId;
      await getTotalPages();
    }
  } catch (e) {
    console.error(e)
  }
}

const getUserByToken = async () => {
  try {
    const { data } = await userService.getUserByToken(token);
    Object.assign(user, { ...data });
    await getInternships()
  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit('handleLogout')
      await router.push({path: '/inloggen'})
    }
    console.error(error);
  }

};


const getTotalPages = async (newId) => {
  try {
    const { data } = await internshipTimeService.getTotalPages(parseInt(selectedInternship.value));
      await router.push(`/stagetijden/${newId}/${data}`);

  } catch (e) {
    console.error(e);
  }
};




watch(selectedInternship, async (newId) => {
  await getTotalPages(newId);
  emit('updateSelectedInternship', newId);
});


onMounted(() => {
  getUserByToken()
})
</script>
