<template>
  <div class="w-full">
    <div class="mb-5">
      <label for="data"  class="dark:bg-stone-800 dark:text-stone-100 ">
        <select v-model="selected" class="w-36 select-none focus:outline-none dark:bg-stone-800 dark:text-stone-100 input-field focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent hover:border-b-orange-600  border-t hover:border-t-orange-600 border-l hover:border-l-orange-600  border-r hover:border-r-orange-600" name="data" id="data">
          <option disabled value="0">Selecteer een jaar</option>
          <option v-for="year in years.list" :key="year.id" :value="year.id">{{ year.year }}</option>
        </select>
      </label>
    </div>
    <table v-if="selected" class="table-auto dark:text-stone-100 shadow-own">
      <thead class="border-black dark:border-white border-2 ">
      <tr>
        <th class="p-1 md:p-2 break-words whitespace-normal text-left">Maand</th>
        <th class="p-1 md:p-2 text-left">
          <button @click.prevent="createMonth" class="rounded h-7 w-7 p-2 bg-green-500 shadow-own hover:bg-green-700 dark:bg-green-700 dark:hover:bg-green-900 duration-200">
            <img class="h-full w-full" src="../../../../assets/plus-white.png" alt="Plus"/>
          </button>
        </th>
      </tr>
      </thead>
      <tbody class="border-black dark:border-white border-2">
      <tr v-for="month in months.list" :key="month.id">
        <td class="p-1  md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border break-words whitespace-normal">{{ month.month }}</td>
        <td class="p-1 md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border">
          <div class="flex gap-1">
            <button @click.prevent="getMonth(month.id)" class="rounded h-7 w-7 p-2 bg-black dark:bg-stone-100 shadow-own hover:bg-stone-700 dark:hover:bg-stone-300 duration-200">
              <img class="h-full w-full" :src="isDark ? require(`../../../../assets/info-black.png`) : require('../../../../assets/info-white.png')" alt="Info"/>
            </button>
            <button @click.prevent="editMonth(month.id)" class="rounded h-7 w-7 p-2 bg-yellow-500 shadow-own hover:bg-yellow-700 dark:bg-yellow-700 dark:hover:bg-yellow-900 duration-200">
              <img  class="h-full w-full" src="../../../../assets/edit-white.png" alt="Bin"/>
            </button>
            <button @click.prevent="deleteMonth(month.id,)" class="rounded h-7 w-7 p-2 bg-red-500 shadow-own hover:bg-red-700 dark:bg-red-700 dark:hover:bg-red-900 duration-200">
              <img class="h-full w-full" src="../../../../assets/bin.png" alt="Bin"/>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import {inject, onMounted, reactive, ref, watch} from "vue";
import {useDark} from "@vueuse/core";
import {useRouter} from "vue-router";
import {useStore} from "vuex";

const router = useRouter();
const selected = ref(null)
const {monthService,yearService} = inject('data')
const months = reactive({list: []})
const years = reactive({list: []})
const isDark = useDark()
const store = useStore();


const deleteMonth = async (monthId) => {
  try {
    const {message} = await monthService.removeMonth(monthId)
    store.commit('setSuccessMessage', message)
    await getAllMonths(selected.value)
  } catch (e) {
    store.commit('setErrorMessage', e.message)
  }
}
const getAllYears = async () => {
  try {
    const { data } = await yearService.getAllYears();
    // Filter out the logged-in user from the list of all users
    years.list = data.map(u => ({...u}));

  } catch (e) {
    console.error(e);
  }
};

const getMonth = async (id) => {
  await router.push(`/profiel/admin-panel/maand/${id}`)
}

const createMonth = async () => {
  await router.push(`/profiel/admin-panel/maand/aanmaken`)
}

const editMonth = async (id) => {
  await router.push(`/profiel/admin-panel/maand/wijzigen/${id}`)
}

const getAllMonths = async () => {
  try {
      const {data} = await monthService.getAllMonthsByYearId(selected.value);
      // Filter out the logged-in user from the list of all users
      months.list = data.map(u => ({...u}));
  } catch (e) {
    console.error(e);
  }
};

watch(() => selected.value, () => {
  getAllMonths(selected.value)
})

onMounted(() => {
  getAllYears()
  getAllMonths(selected.value)
})


</script>

