<template>
  <button @click.prevent="back" class="mb-2 dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button">Terug</button>
  <div class="flex gap-4 flex-col md:flex-row">
    <div class="flex flex-col gap-2 w-52 space-y-4">
      <div>
        <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Email</h1>
        <p class="text-gray-600 dark:text-gray-400">{{user.email}}</p>
      </div>
      <div>
        <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Voornaam</h1>
        <p class="text-gray-600 dark:text-gray-400">{{user.firstname}}</p>
      </div>
      <div>
        <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Achternaam</h1>
        <p class="text-gray-600 dark:text-gray-400">{{user.lastname}}</p>
      </div>
      <div>
        <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Telefoonnummer</h1>
        <p class="text-gray-600 dark:text-gray-400">{{user.phoneNumber}}</p>
      </div>
      <div>
        <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Uurloon</h1>
        <p class="text-gray-600 dark:text-gray-400">€ {{user.hourlyWage}}</p>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <img class="shadow-own object-cover aspect-square rounded-lg " :src="`https://www.rickveerman.nl/${user?.profilePicture}`" alt="">
      <div class=" shadow-own p-4">
        <h1 class="mb-2 text-lg font-bold text-gray-800 dark:text-gray-200">Rollen</h1>
        <div class="flex flex-col  gap-2">
          <div v-for="role in user.roles" :key="role.id"  class="flex justify-between">
            <ol>
              <li class="list-disc ml-4 text-gray-600 dark:text-gray-400" >{{ role.frontendName}}</li>
            </ol>
            <button @click.prevent="removeRole(role.id)" class="h-7 w-7 p-2 bg-red-500 shadow-own hover:bg-red-700 md:bg-red-700 md:hover:bg-red-900 duration-200">
              <img class="h-full w-full" src="../../../../../assets/bin.png" alt="Bin"/>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <label for="data"  class="dark:bg-stone-800 dark:text-stone-100  ">
        <select v-model="selected"  class="w-full select-none focus:outline-none dark:bg-stone-800 dark:text-stone-100 input-field focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent hover:border-b-orange-600  border-t hover:border-t-orange-600 border-l hover:border-l-orange-600  border-r hover:border-r-orange-600" name="data" id="data">
          <option disabled value="0">Selecteer een rol</option>
          <option v-for="role in roles.list" :key="role.id" :value="`${role.id}`">{{ role.frontendName }}</option>
        </select>
      </label>
      <button class="" type="submit" :disabled="roleValid === false" @click.prevent="addRole" :class="buttonClass">Rol Toevoegen</button>
    </div>

  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {computed, inject, onMounted, reactive, ref} from "vue";
import {useStore} from "vuex";
const router = useRouter();
const roles = reactive({list: []})
const route = useRoute();
const selected = ref(null)
const userId = ref(route.params.id)
const store = useStore();
const {userService, roleService} = inject('data')
const user = reactive( {
  id: '',
  email: '',
  firstname: '',
  lastname: '',
  phoneNumber: '',
  hourlyWage: '',
  roles: [
  ]
})
const getUserById = async () => {
  try {
    const {data} = await userService.getUserById(userId.value)
    Object.assign(user, { ...data });
    user.hourlyWage = user.hourlyWage.toLocaleString('nl-NL', { minimumFractionDigits: 2 });
    await getRoles()
  } catch (error) {
    console.error(error)
  }
}

const addRole = async () => {
  try {
    const {message} = await userService.addRole(user, selected.value)
    store.commit('setSuccessMessage', message)
    selected.value = null
    await getUserById()
  } catch (error) {
    store.commit('setErrorMessage', error.message)

  }
}

const removeRole = async (roleId) => {
  try {
    const {message} = await userService.removeRole(user, roleId)
    store.commit('setSuccessMessage', message)
    selected.value = null
    await getUserById()
  } catch (error) {
    store.commit('setErrorMessage', error.message)

  }
}

const back = () => {
  router.push("/profiel/admin-panel/gebruikers")
}

const getRoles = async () => {
  try {
    const { data } = await roleService.getAllRoles()
    roles.list = data
        .filter(r => !user.roles.some(ur => ur.id === r.id))
        .map(r => ({...r}))
  } catch (e) {
    console.error(e)
  }
}

const roleValid = computed(() => {
  return !(selected.value === '0' || selected.value === null || selected.value === undefined);
})

const buttonClass = computed(() => {
  if (selected.value === '0' || selected.value === null || selected.value === undefined) {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 pb-2 pt-6 pr-4 pl-4 rounded border-b-4 button opacity-50 cursor-not-allowed";
  } else {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button";
  }
});

onMounted(() => {
  getUserById()
})

</script>