<template>
  <div >
    <div v-if="!noSelect"  class="mb-5 ">
    <label for="data"  class="dark:bg-stone-800 dark:text-stone-100  ">
      <select v-model="selected" class="w-36 select-none focus:outline-none dark:bg-stone-800 dark:text-stone-100 input-field focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent hover:border-b-orange-600  border-t hover:border-t-orange-600 border-l hover:border-l-orange-600  border-r hover:border-r-orange-600" name="data" id="data">
        <option value="users">Gebruikers</option>
        <option value="years">Jaren</option>
        <option value="months">Maanden</option>
      </select>
    </label>
    </div>
    <router-view  class="w-full"></router-view>
  </div>
</template>

<script setup>

import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
const router = useRouter();
const route = useRoute();
const selected = ref('');
watch(selected, (newValue) => {
  router.push({ name: newValue });
});

watch(() => route.name, (newValue) => {
  // Only update the selected value if the current route is within the 'admin-panel'
  if (route.matched.some(record => record.name === 'Admin Panel')) {
    selected.value = newValue;
  }
});

const noSelect = computed(() => {
  return route.path.includes("aanmaken") || route.params.id !== undefined;
})

onMounted(() => {
  selected.value = route.name
})


onUnmounted(() => {
  selected.value = ''
})
</script>