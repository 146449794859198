<template>
  <div>
    <button @click.prevent="back" class="mb-2 dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button">Terug</button>
    <form class="flex flex-col my-4 gap-2">
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': copyUser.email }" for="email">Email</label>
        <input v-model="copyUser.email"  @input="validateEmail" :class="emailClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="email"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{emailError}}</p>
      </div>
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': copyUser.firstname }" for="firstname">Voornaam</label>
        <input v-model="copyUser.firstname"  @input="validateFirstname" :class="firstnameClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="firstname"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ firstnameError}}</p>
      </div>
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': copyUser.lastname }" for="lastname">Achternaam</label>
        <input v-model="copyUser.lastname" @input="validateLastname" :class="lastnameClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="lastname"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ lastnameError}}</p>
      </div>
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': copyUser.phoneNumber }" for="phoneNumber">Telefoonnummer</label>
        <input v-model="copyUser.phoneNumber" @input="validatePhoneNumber" :class="phoneNumberClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="phoneNumber"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ phoneNumberError}}</p>
      </div>
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': copyUser.hourlyWage }" for="hourlyWage">Uurloon</label>
        <input v-model="copyUser.hourlyWage" @input="validateHourlyWage" :class="hourlyWageClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="hourlyWage"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ hourlyWageError}}</p>
      </div>
      <div v-if="isParent" class="flex flex-col relative input-container">
        <label for="data"  class="dark:bg-stone-800 dark:text-stone-100">
          <select v-model="copyUser.childId"  class="w-full select-none focus:outline-none dark:bg-stone-800 dark:text-stone-100 input-field focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent hover:border-b-orange-600  border-t hover:border-t-orange-600 border-l hover:border-l-orange-600  border-r hover:border-r-orange-600" name="data" id="data">
            <option disabled value="0">Selecteer een rol</option>
            <option :value="null"></option>

            <option v-for="user in users.list" :key="user.id" :value="`${user.id}`">{{ user.email }}</option>
          </select>
        </label>
      </div>
      <div class="flex w-full justify-between items-center">
        <button type="submit" :disabled="isEdited === false || emailValid === false || firstnameValid === false || lastnameValid === false || phoneNumberValid === false || hourlyWageValid === false || !copyUser.firstname || !copyUser.email || !copyUser.lastname || !copyUser.phoneNumber || !copyUser.hourlyWage" @click.prevent="editUser" :class="buttonClass">Wijzigen</button>
      </div>
    </form>
  </div>
</template>

<script setup>
import {computed, inject, onMounted, reactive, ref} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
const store = useStore();
const user = reactive({
  id:'',
  email: '',
  firstname: '',
  lastname: '',
  phoneNumber: '',
  hourlyWage: '',
  childId: ''
})
const copyUser = reactive({
  id:'',
  email: '',
  firstname: '',
  lastname: '',
  phoneNumber: '',
  hourlyWage: '',
  childId: ''
})
const route = useRoute();
const router = useRouter();
const loggedInUser = reactive({
  id: '',
  email: '',
  firstname: '',
  lastname: '',
  phoneNumber: '',
  hourlyWage: '',
  childId: '',
});
const {userService, sessionSBService} = inject('data')
const emailValid = ref(null)
const firstnameValid = ref(null)
const lastnameValid = ref(null)
const phoneNumberValid = ref(null)
const hourlyWageValid = ref(null)
const emailError = ref('')
const firstnameError = ref('')
const lastnameError = ref('')
const phoneNumberError = ref('')
const hourlyWageError = ref('')
const createSuccessful = ref(null)
const userId = ref('')
const isParent = ref(false)
const back = () => {
  router.push("/profiel/admin-panel/gebruikers")
}

const token = sessionSBService.getTokenFromBrowserStorage()

const getUserById = async () => {
  try {
    const {data} = await userService.getUserById(userId.value)
    Object.assign(user, { ...data });
    user.hourlyWage = user.hourlyWage.toLocaleString('nl-NL', { minimumFractionDigits: 2 });
    Object.assign(copyUser, {...user})
    data.roles.forEach(role => {
      if (role.frontendName === 'Ouder') {
        isParent.value = true;
      }
    });

    await getAllUsers()
  } catch (error) {
    console.error(error)
  }
}

const editUser = async () => {
  try {
    copyUser.hourlyWage = parseFloat(copyUser.hourlyWage.replace(",","."))

    const { message } =  await userService.updateUser(copyUser)
    store.commit('setSuccessMessage', message)
    await router.push({path: '/profiel/admin-panel/gebruikers'})
    createSuccessful.value = true
  } catch (e) {
    createSuccessful.value = false
    emailValid.value = false
    firstnameValid.value = false
    lastnameValid.value = false
    phoneNumberValid.value = false
    hourlyWageValid.value = false
    store.commit('setErrorMessage', e.message)

  }
}
const users = reactive({list: []})

const isEdited = computed(() => {
  const isEmailEdited = (user.email !== copyUser.email)
  const isFirstnameEdited = (user.firstname !== copyUser.firstname)
  const isLastnameEdited = (user.lastname !== copyUser.lastname)
  const isPhoneNumberEdited = (user.phoneNumber !== copyUser.phoneNumber)
  const isHourlyWageEdited = (user.hourlyWage !== copyUser.hourlyWage)
  const isChildWageEdited = (user.childId !== copyUser.childId)
  return (isEmailEdited || isFirstnameEdited || isLastnameEdited || isPhoneNumberEdited || isHourlyWageEdited || isChildWageEdited)

});



const validateEmail = () => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (copyUser.email === "") {
    emailError.value = "Email mag niet leeg zijn.";
    emailValid.value = false;
  } else if (emailPattern.test(copyUser.email)) {
    emailError.value = "";
    emailValid.value = true;
  } else {
    emailError.value = "Ongeldig e-mailadres.";
    emailValid.value = false;
  }
}

const validateFirstname = () => {
  const firstnamePattern = /^[A-Z][a-zA-Z]*$/;
  if (copyUser.firstname === "") {
    firstnameError.value = "Voornaam mag niet leeg zijn.";
    firstnameValid.value = false;
  } else if (firstnamePattern.test(copyUser.firstname)) {
    firstnameError.value = "";
    firstnameValid.value = true;
  } else if (!/^[A-Z]/.test(copyUser.firstname)) {
    firstnameError.value = "Voornaam moet beginnen met een hoofdletter.";
    firstnameValid.value = false;
  } else {
    firstnameError.value = "Voornaam mag alleen alfabetische tekens bevatten.";
    firstnameValid.value = false;
  }
}

const validateLastname = () => {
  const lastnamePattern = /\b[A-Z][a-zA-Z]*\b/;
  if (copyUser.lastname === "") {
    lastnameError.value = "Achternaam mag niet leeg zijn.";
    lastnameValid.value = false;
  } else if (lastnamePattern.test(copyUser.lastname)) {
    lastnameError.value = "";
    lastnameValid.value = true;
  } else {
    lastnameError.value = "Achternaam moet minstens één woord met een hoofdletter bevatten.";
    lastnameValid.value = false;
  }
}

const validatePhoneNumber = () => {
  const localPhoneNumberPattern = /^0[1-9][0-9]{8}$/;
  const internationalPhoneNumberPattern = /^(?:\+31|0031)[1-9][0-9]{8}$/;
  if (copyUser.phoneNumber === "") {
    phoneNumberError.value = "Telefoonnummer mag niet leeg zijn.";
    phoneNumberValid.value = false;
  } else if (localPhoneNumberPattern.test(copyUser.phoneNumber) || internationalPhoneNumberPattern.test(copyUser.phoneNumber)) {
    phoneNumberError.value = "";
    phoneNumberValid.value = true;
  } else {
    phoneNumberError.value = "Ongeldig Nederlands telefoonnummer.";
    phoneNumberValid.value = false;
  }
}

const validateHourlyWage = () => {
  const hourlyWagePattern = /^[0-9]+([,.][0-9]{2})?$/;
  if (copyUser.hourlyWage === "") {
    hourlyWageError.value = "Uurloon mag niet leeg zijn.";
    hourlyWageValid.value = false;
  } else if (hourlyWagePattern.test(copyUser.hourlyWage)) {
    hourlyWageError.value = "";
    hourlyWageValid.value = true;
  } else {
    hourlyWageError.value = "Ongeldig uurloon. Gebruik precies twee cijfers na de komma indien aanwezig.";
    hourlyWageValid.value = false;
  }
}


const emailClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (emailValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const firstnameClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (firstnameValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const lastnameClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (lastnameValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const phoneNumberClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (phoneNumberValid.value === false ) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const hourlyWageClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (hourlyWageValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const getUserByToken = async () => {
  try {
    const {data} = await userService.getUserByToken(token)
    Object.assign(loggedInUser, { ...data });
    loggedInUser.hourlyWage = loggedInUser.hourlyWage.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    await getUserById()
  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      await router.push({path: '/inloggen'})
    }
    console.error(error)
  }
}

const getAllUsers = async () => {
  try {
    const { data } = await userService.getAllUsers();
    // Filter out the logged-in user from the list of all users
    users.list = data
        .filter(user => user.childId == null)
        .map(u => ({
          ...u,
          hourlyWageFormatted: u.hourlyWage.toLocaleString('nl-NL', { minimumFractionDigits: 2 })
        }));
  } catch (e) {
    console.error(e);
  }
};

const buttonClass = computed(() => {
  if (!isEdited.value || emailValid.value === false || firstnameValid.value === false || lastnameValid.value === false || phoneNumberValid.value === false || hourlyWageValid.value === false || !copyUser.firstname || !copyUser.email || !copyUser.lastname || !copyUser.phoneNumber || !copyUser.hourlyWage) {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 pb-2 pt-6 pr-4 pl-4 rounded border-b-4 button opacity-50 cursor-not-allowed";
  } else {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button";
  }
});

onMounted(() => {
  userId.value = route.params.id

  getUserByToken()
})
</script>