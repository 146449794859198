<template>
  <div class="dark:bg-stone-800 p-8 ">
    <div class="w-8/12 m-auto">
    <button @click.prevent="back" class="mb-2 dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button">Terug</button>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div class="flex flex-col space-y-4">
        <div>
          <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200 ">Dag</h1>
          <p class="text-gray-600 dark:text-gray-400">{{ workingTime.dayName }}</p>
        </div>
        <div>
          <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Datum</h1>
          <p class="text-gray-600 dark:text-gray-400">{{ workingTime.date }}</p>
        </div>
        <div>
          <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Vanaf</h1>
          <p class="text-gray-600 dark:text-gray-400">{{ workingTime.startTime }}</p>
        </div>
        <div>
          <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Tot</h1>
          <p class="text-gray-600 dark:text-gray-400">{{ workingTime.endTime }}</p>
        </div>
      </div>
      <div class="flex flex-col space-y-4">
        <div>
          <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Fooi</h1>
          <p class="text-gray-600 dark:text-gray-400">€ {{ workingTime.tipFormatted }}</p>
        </div>
        <div>
          <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Loon</h1>
          <p class="text-gray-600 dark:text-gray-400">€ {{ workingTime.wage }}</p>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script setup>
import {inject, onMounted, reactive} from "vue";
import {useRoute, useRouter} from "vue-router";
import useEventsBus from "../../../eventBus";
const workingTime = reactive({
  id:'',
  day: '',
  dayName: '',
  date: '',
  tipFormatted: '',
  wage: '',
  startTime: '',
  endTime: '',
  tip: '',
  holiday: '',
  month: {
    id: '',
    month: '',
    year: {
      id: '',
      year: ''
    }
  }
})

const user = reactive({
  id: '',
  birthDate: '',
  age: '',
  hourlyWage: ''
});
const route = useRoute()
const { emit } = useEventsBus();
const router = useRouter()
const { workingTimeService, userService, sessionSBService } = inject('data');
const token = sessionSBService.getTokenFromBrowserStorage();

const getUserByToken = async () => {
  try {
    const { data } = await userService.getUserByToken(token);
    Object.assign(user, { ...data });
    await getWorkingTime()
  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit('handleLogout')
      await router.push({path: '/inloggen'})
    }
    console.error(error);
  }
};

const getWorkingTime = async () => {
  try {
    const { data } = await workingTimeService.getWorkingTimeById(route.params.id)
    Object.assign(workingTime, { ...data });
    workingTime.dayName = getDayNameInDutch(workingTime.month.year.year, getMonthIndexFromDutchName(workingTime.month.month), workingTime.day)
    workingTime.date = `${workingTime.day} - ${getMonthIndexFromDutchName(workingTime.month.month) + 1}`
    workingTime.wage = calculateWage(workingTime.startTime, workingTime.endTime, user.hourlyWage, workingTime.holiday)
    workingTime.tipFormatted = workingTime.tip.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
  } catch (e) {
    console.error(e)
  }
}

// eslint-disable-next-line no-unused-vars
const calculateWage = (startTime, endTime, hourlyWage, holiday) => {
  const [startHour, startMinute] = startTime.split(':').map(Number);
  let endHour, endMinute;

  if (endTime.toLowerCase() === 'sluit') {
    // Calculate end time based on month and day of the week
    const date = new Date();
    const month = date.getMonth(); // Month index is zero-based
    const dayOfWeek = date.getDay(); // 0 is Sunday, 1 is Monday, etc.

    // Define end time based on month and day of the week
    if ((month >= 3 && month <= 9) || (month === 10 && dayOfWeek === 0) || (month === 10 && dayOfWeek === 6)) {
      // April to October or November (if it falls on a weekend)
      endHour = 22;
      endMinute = dayOfWeek === 0 ? 30 : 0; // Sunday: 22:30, otherwise 22:00
    } else {
      // November to March or November (if it falls on a weekday)
      endHour = 21;
      endMinute = dayOfWeek === 0 ? 30 : 0; // Sunday: 21:30, otherwise 21:00
    }
  } else {
    // If endTime is not "sluit", parse it as a regular time
    [endHour, endMinute] = endTime.split(':').map(Number);
  }

  let hoursWorked;
  if (endHour < startHour || (endHour === startHour && endMinute < startMinute)) {
    // If endTime is earlier than startTime, it's assumed to be on the next day
    hoursWorked = (24 - startHour + endHour) + ((endMinute - startMinute) / 60);
  } else {
    hoursWorked = (endHour - startHour) + ((endMinute - startMinute) / 60);
  }

  calculateAge()
  let wage = hoursWorked * hourlyWage

  if (holiday) {
    wage = wage * 1.25
  }
  if (user.age >= 21) {
    const pension = 19.50
    const min = wage / pension
    const total = wage - min
    return total.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) // Calculating wage and fixing to 2 decimal places
  }
  return wage.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) // Calculating wage and fixing to 2 decimal places
};

const calculateAge = () => {
  if (user.birthDate == null) return 0; // Return 0 if birthDate is empty or undefined

  const birth = new Date(user.birthDate);
  const today = new Date();
  let age = today.getFullYear() - birth.getFullYear();
  const m = today.getMonth() - birth.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
    age--;
  }
  user.age = age
}

const back = async () => {
  await router.push(`/werktijden/${workingTime.month.year.id}/${workingTime.month.id}`)
}
const getDayNameInDutch = (year, monthIndex, day) => {
  const date = new Date(Date.UTC(year, monthIndex, day));
  const formatter = new Intl.DateTimeFormat('nl-NL', { weekday: 'long', timeZone: 'UTC' });
  const dayName = formatter.format(date);
  return dayName.charAt(0).toUpperCase() + dayName.slice(1).toLowerCase();
};

const getMonthIndexFromDutchName = (monthName) => {
  const monthNamesDutch = [
    'Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni',
    'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'
  ];

  return monthNamesDutch.findIndex(name => name.toLowerCase() === monthName.toLowerCase());
};

onMounted(() => {
  getUserByToken()
})
</script>

