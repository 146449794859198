<template>
  <div class="dark:bg-stone-800 p-7 flex justify-center">
    <div class="w-8/12">
      <button @click.prevent="back" class="mb-2 dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button">Terug</button>
      <form class="flex flex-col my-4 gap-2">
        <div class="flex flex-col relative input-container">
          <label id="date-label" class="p-2 label dark:text-stone-100 active" for="date">Datum</label>
          <input  v-model="internshipTime.date" @input="validateDate" :class="dateClass && isDark ? 'dark dark:border-b-stone-600' : 'border-b-stone-200'" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="date" id="date"/>
          <p class="p-1 error-min-height text-red-500 text-xs italic">{{ dateError }}</p>
        </div>
        <div class="flex flex-col relative input-container">
          <label class="p-2 label dark:text-stone-100" :class="{ 'active': internshipTime.startTime }" for="startTime">Vanaf</label>
          <input v-model="internshipTime.startTime" @input="validateStartTime" :class="startTimeClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="startTime"/>
          <p class="p-1 error-min-height text-red-500 text-xs italic">{{ startTimeError }}</p>
        </div>
        <div class="flex flex-col relative input-container">
          <label class="p-2 label dark:text-stone-100" :class="{ 'active': internshipTime.endTime }" for="endTime">Tot</label>
          <input v-model="internshipTime.endTime" @input="validateEndTime" :class="endTimeClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="endTime"/>
          <p class="p-1 error-min-height text-red-500 text-xs italic">{{ endTimeError }}</p>
        </div>
        <div class="flex flex-col relative input-container">
          <label ref="des_label" class="p-2 label dark:text-stone-100" :class="{ 'active': internshipTime.description }" for="description">Beschrijving</label>
          <textarea ref="textarea" v-model="internshipTime.description" :class="descriptionClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600 border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" id="description"></textarea>
          <p class="p-1 error-min-height text-red-500 text-xs italic">{{ tipError }}</p>
        </div>
        <div class=" w-full ">
          <button class="w-full" type="submit" :disabled="dateValid === false || startTimeValid === false || endTimeValid === false || !internshipTime.date || !internshipTime.startTime || !internshipTime.endTime" @click.prevent="createInternshipTime" :class="buttonClass">Aanmaken</button>
        </div>
      </form>
    </div>
  </div>

</template>

<script setup>
import { computed, inject, onMounted, reactive, ref } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import { useDark } from "@vueuse/core";
import useEventsBus from "../../../eventBus";
import {useRoute} from "vue-router";

const store = useStore();
const isDark = useDark();
const internshipTime = reactive({
  id: '',
  date: '',
  startTime: '10:00',
  endTime: '17:00',
  description: ''
});
const user = reactive({
  id: ''
});


const { userService, sessionSBService, internshipTimeService } = inject('data');
const dateValid = ref(null);
const startTimeValid = ref(null);
const endTimeValid = ref(null);
const dateError = ref('');
const startTimeError = ref('');
const route = useRoute();
const endTimeError = ref('');
const { emit } = useEventsBus();
const tipError = ref('');
const createSuccessful = ref(null);
const token = sessionSBService.getTokenFromBrowserStorage();

const getUserByToken = async () => {
  try {
    const { data } = await userService.getUserByToken(token);
    Object.assign(user, { ...data });


  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit('handleLogout')

      await router.push({path: '/inloggen'})
    }
    console.error(error);
  }

};


const back = async () => {
  await router.push(`/stagetijden/${route.params.internshipId}/${route.params.pageNo}`);
};

const validateDate = () => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // Regular expression for validating date in YYYY-MM-DD format
  if (!dateRegex.test(internshipTime.date)) {
    dateError.value = "Ongeldige datum. Gebruik het formaat YYYY-MM-DD.";
    dateValid.value = false;
  } else {
    dateError.value = "";
    dateValid.value = true;
  }
};



const createInternshipTime = async () => {
  try {
    const { message } = await internshipTimeService.createInternshipTime(internshipTime, route.params.internshipId);
    store.commit('setSuccessMessage', message);
    await router.push(`/stagetijden/${route.params.internshipId}/${route.params.pageNo}`);
  } catch (e) {
    store.commit('setErrorMessage', e.message);
  }
};

const validateStartTime = () => {
  const timeRegex = /^(?:2[0-3]|[01]?[0-9]):[0-5]?[0-9]$/; // Regular expression for validating time in HH:mm format
  if (!timeRegex.test(internshipTime.startTime)) {
    startTimeError.value = "Ongeldige starttijd. Gebruik het formaat HH:mm.";
    startTimeValid.value = false;
  } else {
    startTimeError.value = "";
    startTimeValid.value = true;
  }
};

const validateEndTime = () => {
  const timeRegex = /^(?:2[0-3]|[01]?[0-9]):[0-5]?[0-9]$/; // Regular expression for validating time in HH:mm format
  if (internshipTime.endTime.toLowerCase() !== 'sluit' && !timeRegex.test(internshipTime.endTime)) {
    endTimeError.value = "Ongeldige eindtijd. Gebruik het formaat HH:mm of 'sluit'.";
    endTimeValid.value = false;
  } else {
    endTimeError.value = "";
    endTimeValid.value = true;
  }
};

const dateClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (dateValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
});

const startTimeClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (startTimeValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
});

const endTimeClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (endTimeValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
});

const descriptionClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
});

const buttonClass = computed(() => {
  if (dateValid.value === false || startTimeValid.value === false || endTimeValid.value === false || !internshipTime.date || !internshipTime.startTime || !internshipTime.endTime) {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 pb-2 pt-6 pr-4 pl-4 rounded border-b-4 button opacity-50 cursor-not-allowed";
  } else {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button";
  }
});



onMounted(() => {
  getUserByToken();
});

</script>