<template>
  <div class="dark:bg-stone-800 p-8 ">
    <div class="w-8/12 m-auto">
    <button @click.prevent="back" class="mb-2 dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button">Terug</button>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div class="flex flex-col space-y-4">
        <div>
          <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200 ">Datum</h1>
          <p class="text-gray-600 dark:text-gray-400">{{ formatDate(internshipTime.date) }}</p>
        </div>
        <div>
          <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Vanaf</h1>
          <p class="text-gray-600 dark:text-gray-400">{{ internshipTime.startTime }}</p>
        </div>
        <div>
          <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Tot</h1>
          <p class="text-gray-600 dark:text-gray-400">{{ internshipTime.endTime }}</p>
        </div>
        <div>
          <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Beschrijving</h1>
          <p class="text-gray-600 dark:text-gray-400"  v-html="formatDescription(internshipTime.description)"></p>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script setup>
import {inject, onMounted, reactive} from "vue";
import {useRoute, useRouter} from "vue-router";
import useEventsBus from "../../../eventBus";
const internshipTime = reactive({
  id:'',
  date: '',
  startTime: '',
  endTime: '',
  description: '',
})

const user = reactive({
  id: '',
  birthDate: '',
  age: ''
});
const route = useRoute()
const { emit } = useEventsBus();
const router = useRouter()
const { internshipTimeService, userService, sessionSBService } = inject('data');
const token = sessionSBService.getTokenFromBrowserStorage();
const getUserByToken = async () => {
  try {
    const { data } = await userService.getUserByToken(token);
    Object.assign(user, { ...data });
    await getWorkingTime()
  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit('handleLogout')

      await router.push({path: '/inloggen'})
    }
    console.error(error);
  }
};



const getWorkingTime = async () => {
  try {
    const { data } = await internshipTimeService.getInternshipTimeById(route.params.id)
    Object.assign(internshipTime, { ...data });
  } catch (e) {
    console.error(e)
  }
}

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
const back = async () => {
  await router.push(`/stagetijden/${route.params.internshipId}/${route.params.pageNo}`);
}
const formatDescription = (description) => {
  if (description !== undefined) {
    return description.replace("\n", "<br>");
  }
};

onMounted(() => {
  getUserByToken()
})
</script>

