<template>
  <table class="table-auto dark:text-stone-100 shadow-own">
    <thead class="border-black dark:border-white border-2">
    <tr>
      <th class="p-1 md:p-2 break-words whitespace-normal text-left">Bedrijf</th>
      <th class="p-1 md:p-2 text-left">
        <button @click.prevent="createInternship" class="rounded h-7 w-7 p-2 bg-green-500 shadow-own hover:bg-green-700 dark:bg-green-700 dark:hover:bg-green-900 duration-200">
          <img class="h-full w-full" src="../../../assets/plus-white.png" alt="Plus" />
        </button>
      </th>
    </tr>
    </thead>
    <tbody class="border-black dark:border-white border-2">
    <tr v-for="internship in internships.list" :key="internship.id">
      <td class="p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border break-words whitespace-normal">{{ internship.company }}</td>
      <td class="p-1 md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border">
        <div class="flex gap-1">
          <button @click.prevent="getInternship(internship.id)" class="rounded h-7 w-7 p-2 bg-black dark:bg-stone-100 shadow-own hover:bg-stone-700 dark:hover:bg-stone-300 duration-200">
            <img class="h-full w-full" :src="isDark ? require(`../../../assets/info-black.png`) : require('../../../assets/info-white.png')" alt="Info" />
          </button>
          <button @click.prevent="editInternship(internship.id)" class="rounded h-7 w-7 p-2 bg-yellow-500 shadow-own hover:bg-yellow-700 dark:bg-yellow-700 dark:hover:bg-yellow-900 duration-200">
            <img class="h-full w-full" src="../../../assets/edit-white.png" alt="Edit" />
          </button>
          <button @click.prevent="deleteInternship(internship.id)" class="rounded h-7 w-7 p-2 bg-red-500 shadow-own hover:bg-red-700 dark:bg-red-700 dark:hover:bg-red-900 duration-200">
            <img class="h-full w-full" src="../../../assets/bin.png" alt="Delete" />
          </button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>


<script setup>
import {inject, onMounted, reactive} from "vue";
import {useDark} from "@vueuse/core";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import useEventsBus from "../../../../eventBus";

const { sessionSBService, userService, internshipService } = inject("data");
const isDark = useDark();
const router = useRouter();
const { emit } = useEventsBus();
const user = reactive({
  id: ""
});
const internships = reactive({
  list: {
  }
});
const store = useStore();
const token = sessionSBService.getTokenFromBrowserStorage();
const getUserByToken = async () => {
  try {

    const { data } = await userService.getUserByToken(token);
    Object.assign(user, { ...data });
    await getAllInternshipTimes();
  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit('handleLogout')

      await router.push({path: '/inloggen'})
    }
    console.error(error);
  }
};

const editInternship = async (internshipId) => {
  await router.push(`/stage/wijzigen/${internshipId}`);
};

const getInternship = async (internshipId) => {
  await router.push(`/stage/${internshipId}`);
};



const deleteInternship = async (internShipId) => {
  try {
    const { message } = await internshipService.removeInternship(internShipId);
    store.commit("setSuccessMessage", message);
    await getAllInternshipTimes();
  } catch (e) {
    store.commit("setErrorMessage", e.message);
  }
};

const createInternship = async () => {
  await router.push(`/stage/aanmaken`);
};


const getAllInternshipTimes = async () => {
  try {
    const { data } = await internshipService.getInternshipsByUser(user);
    internships.list = data.map(i => ({
      ...i,
    }));
  } catch (e) {
    console.error(e);
  }
};


onMounted(() => {
  getUserByToken();
});

</script>
