<template>
  <div class="dark:bg-stone-800 flex md:p-7 p-2 justify-center ">
    <div class="w-11/12 md:w-8/12  flex justify-center flex-col gap-4">
      <h1 class="dark:text-stone-100 text-2xl font-bold">Stages</h1>
      <router-view></router-view>
    </div>
  </div>
</template>
<script setup>
</script>
