// store.js
import { createStore } from 'vuex'

export default createStore({
  state: {
    errorMessage: null,
    successMessage: null
  },
  mutations: {
    setSuccessMessage (state, value) {
      state.successMessage = value
    },
    setErrorMessage (state, value) {
      state.errorMessage = value
    },
  }
})
