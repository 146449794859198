<template>
  <div class="dark:text-stone-100 dark:bg-stone-800 h-72 flex justify-center items-center">
    <div class="text-center">
      <h1 class="text-2xl font-bold">ERROR</h1>
      <h2 class="text-base">Page not found</h2>
    </div>
  </div>
</template>

<script setup>
</script>
