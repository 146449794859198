<template>
  <div class="dark:bg-stone-800 p-7 flex justify-center">
    <div class="w-8/12">
      <button @click.prevent="back" class="mb-2 dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button">Terug</button>
      <form class="flex flex-col my-4 gap-2">
        <div class="flex flex-col relative input-container">
          <label id="date-label" class="p-2 label dark:text-stone-100 active" for="company">Bedrijf</label>
          <input  v-model="internship.company" @input="validateCompany"  :class="companyClass && isDark ? 'dark dark:border-b-stone-600' : 'border-b-stone-200'" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="company"/>
          <p class="p-1 error-min-height text-red-500 text-xs italic">{{ companyError }}</p>
        </div>
        <div class=" w-full ">
          <button class="w-full" type="submit" :disabled="companyValid === false || !internship.company" @click.prevent="createInternship" :class="buttonClass">Aanmaken</button>
        </div>
      </form>
    </div>
  </div>

</template>

<script setup>
import { computed, inject, onMounted, reactive, ref } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import { useDark } from "@vueuse/core";
import useEventsBus from "../../../eventBus";
const store = useStore();
const isDark = useDark();
const internship = reactive({
  id: '',
  company: '',
});
const user = reactive({
  id: ''
});


const { userService, sessionSBService, internshipService } = inject('data');
const companyValid = ref(null);
const { emit } = useEventsBus();
const companyError = ref('');
const createSuccessful = ref(null);
const token = sessionSBService.getTokenFromBrowserStorage();

const getUserByToken = async () => {
  try {
    const { data } = await userService.getUserByToken(token);
    Object.assign(user, { ...data });


  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit('handleLogout')

      await router.push({path: '/inloggen'})
    }
    console.error(error);
  }

};


const back = async () => {
  await router.push(`/stages`);
};





const createInternship = async () => {
  try {
    const { message } = await internshipService.createInternship(internship, user.id);
    store.commit('setSuccessMessage', message);
    await router.push(`/stages`);
  } catch (e) {
    store.commit('setErrorMessage', e.message);
  }
};


const validateCompany = () => {
  if (internship.company === "") {
    companyError.value = "Voornaam mag niet leeg zijn.";
    companyValid.value = false;
  }
}


const companyClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
});

const buttonClass = computed(() => {
  if (companyValid.value === false || !internship.company) {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 pb-2 pt-6 pr-4 pl-4 rounded border-b-4 button opacity-50 cursor-not-allowed";
  } else {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button";
  }
});



onMounted(() => {
  getUserByToken();
});

</script>