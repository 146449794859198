<template>
  <div>
    <div class="dark:text-stone-100 shadow-own rounded pb-2 pt-4" :class="editEmail ? 'pl-2 pr-2' : 'pl-4 pr-4'">
      <div class="flex justify-between items-center gap-1">
        <div class="w-5/6" :class="editEmail ? 'relative' : ''">
          <div class="flex gap-2 items-center">
            <label v-if="!editEmail"  class="text-xs dark:text-stone-100">Email</label>
            <p v-if="isFieldEdited('email') && !editEmail" class="text-xs">*</p>
          </div>
          <h1 v-if="!editEmail"  class="dark:text-stone-100 w-5/6   break-words text-xs md:text-base">{{ copyUser.email }}</h1>
          <label v-if="editEmail" class="p-2 label dark:text-stone-100" :class="{ 'active': copyUser.email }" for="email">Email</label>
          <input v-if="editEmail" v-model="copyUser.email" @input="validateEmail" :class="emailClass" class="w-full dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="email"/>
          <p v-if="editEmail && emailError" class="p-1 error-min-height text-red-500 text-xs italic"> {{ emailError }}</p>
        </div>
        <div v-if="!editEmail" @click.prevent="toggleEditEmail" class="cursor-pointer w-8 h-8  pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded hover:border-b-orange-600 border-b-stone-200">
          <img  class="h-4 w-4 select-none" :src="isDark ? require('../../../assets/edit-white.png') : require('../../../assets/edit-black.png')" alt="">
        </div>
        <div v-if="editEmail" class="flex gap-2 items-center">
          <div @click.prevent="resetEmail"  class="cursor-pointer bg-red-900 w-6 h-6 md:w-8 md:h-8  pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded hover:border-b-red-950 border-b-stone-200">
            <img class="h-2 w-2 md:h-4 md:w-4" src="../../../assets/close.png" alt="Close"/>
          </div>
          <div v-if="isFieldEdited('email')" @click.prevent="saveEmail" class="cursor-pointer w-6 h-6 md:w-8 md:h-8 bg-green-900 pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded hover:border-b-green-950 border-b-stone-200">
            <img class="h-2 w-2 md:h-4 md:w-4" src="../../../assets/check.png" alt="Check"/>
          </div>
          <div v-else class="cursor-pointer w-6 h-6 md:w-8 md:h-8 bg-green-900 pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded opacity-50">
            <img class="h-2 w-2 md:h-4 md:w-4" src="../../../assets/check.png" alt="Check"/>
          </div>
        </div>
      </div>
    </div>
    <div class="dark:text-stone-100 shadow-own rounded pb-2 pt-4" :class="editFirstname ? 'pl-2 pr-2' : 'pl-4 pr-4'">
      <div class="flex justify-between items-center gap-1">
        <div class="w-5/6" :class="editFirstname ? 'relative' : ''">
          <label v-if="!editFirstname"  class="text-xs dark:text-stone-100">Voornaam</label>
          <h1 v-if="!editFirstname"  class="dark:text-stone-100 w-5/6 break-words text-xs md:text-base">{{ copyUser.firstname }}</h1>
          <label v-if="editFirstname" class="p-2 label dark:text-stone-100" :class="{ 'active': copyUser.email }" for="firstname">Voornaam</label>
          <input v-if="editFirstname" v-model="copyUser.firstname" @input="validateFirstname" :class="firstnameClass" class="w-full dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="firstname"/>
          <p v-if="editFirstname && firstnameError" class="p-1 error-min-height text-red-500 text-xs italic"> {{ firstnameError }}</p>
        </div>
        <div v-if="!editFirstname" @click.prevent="toggleEditFirstname" class="cursor-pointer w-8 h-8  pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded hover:border-b-orange-600 border-b-stone-200">
          <img  class="h-4 w-4 select-none" :src="isDark ? require('../../../assets/edit-white.png') : require('../../../assets/edit-black.png')" alt="">
        </div>
        <div v-if="editFirstname" class="flex gap-2 items-center">
          <div @click.prevent="resetFirstname"  class="cursor-pointer bg-red-900 w-8 h-8  pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded hover:border-b-red-950 border-b-stone-200">
            <img class="h-4 w-4" src="../../../assets/close.png" alt="Close"/>
          </div>
          <div v-if="isFieldEdited('firstname')" @click.prevent="saveFirstname" class="cursor-pointer w-8 h-8 bg-green-900 pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded hover:border-b-green-950 border-b-stone-200">
            <img class="h-4 w-4" src="../../../assets/check.png" alt="Check"/>
          </div>
          <div v-else class="cursor-pointer w-8 h-8 bg-green-900 pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded opacity-50">
            <img class="h-4 w-4" src="../../../assets/check.png" alt="Check"/>
          </div>
        </div>
      </div>
    </div>
    <div class="dark:text-stone-100 shadow-own rounded pb-2 pt-4" :class="editLastname ? 'pl-2 pr-2' : 'pl-4 pr-4'">
      <div class="flex justify-between items-center gap-1">
        <div class="w-5/6" :class="editLastname ? 'relative' : ''">
          <label v-if="!editLastname"  class="text-xs dark:text-stone-100">Achternaam</label>
          <h1 v-if="!editLastname"  class="dark:text-stone-100 w-5/6   break-words text-xs md:text-base">{{ copyUser.lastname }}</h1>
          <label v-if="editLastname" class="p-2 label dark:text-stone-100" :class="{ 'active': copyUser.lastname }" for="lastname">Achternaam</label>
          <input v-if="editLastname" v-model="copyUser.lastname" @input="validateLastname" :class="lastnameClass" class="w-full dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="lastname"/>
          <p v-if="editLastname && lastnameError" class="p-1 error-min-height text-red-500 text-xs italic"> {{ lastnameError }}</p>
        </div>
        <div v-if="!editLastname" @click.prevent="toggleEditLastname" class="cursor-pointer w-8 h-8  pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded hover:border-b-orange-600 border-b-stone-200">
          <img  class="h-4 w-4 select-none" :src="isDark ? require('../../../assets/edit-white.png') : require('../../../assets/edit-black.png')" alt="">
        </div>
        <div v-if="editLastname" class="flex gap-2 items-center">
          <div @click.prevent="resetLastname"  class="cursor-pointer bg-red-900 w-8 h-8  pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded hover:border-b-red-950 border-b-stone-200">
            <img class="h-4 w-4" src="../../../assets/close.png" alt="Close"/>
          </div>
          <div v-if="isFieldEdited('lastname')"  @click.prevent="saveLastname" class="cursor-pointer w-8 h-8 bg-green-900 pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded hover:border-b-green-950 border-b-stone-200">
            <img class="h-4 w-4" src="../../../assets/check.png" alt="Check"/>
          </div>
          <div v-else class="cursor-pointer w-8 h-8 bg-green-900 pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded opacity-50">
            <img class="h-4 w-4" src="../../../assets/check.png" alt="Check"/>
          </div>
        </div>
      </div>
    </div>
    <div class="dark:text-stone-100 shadow-own rounded pb-2 pt-4" :class="editPhoneNumber ? 'pl-2 pr-2' : 'pl-4 pr-4'">
      <div class="flex justify-between items-center gap-1">
        <div class="w-5/6" :class="editPhoneNumber ? 'relative' : ''">
          <label v-if="!editPhoneNumber"  class="text-xs dark:text-stone-100">Telefoonnummer</label>
          <h1 v-if="!editPhoneNumber"  class="dark:text-stone-100 w-5/6   break-words text-xs md:text-base">{{ copyUser.phoneNumber }}</h1>
          <label v-if="editPhoneNumber" class="p-2 label dark:text-stone-100" :class="{ 'active': copyUser.phoneNumber }" for="phoneNumber">Telefoonnummer</label>
          <input v-if="editPhoneNumber" v-model="copyUser.phoneNumber" @input="validatePhoneNumber" :class="phoneNumberClass" class="w-full dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="phoneNumber"/>
          <p v-if="editPhoneNumber && phoneNumberError" class="p-1 error-min-height text-red-500 text-xs italic"> {{ phoneNumberError }}</p>
        </div>
        <div v-if="!editPhoneNumber" @click.prevent="toggleEditPhoneNumber" class="cursor-pointer w-8 h-8  pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded hover:border-b-orange-600 border-b-stone-200">
          <img  class="h-4 w-4 select-none" :src="isDark ? require('../../../assets/edit-white.png') : require('../../../assets/edit-black.png')" alt="">
        </div>
        <div v-if="editPhoneNumber" class="flex gap-2 items-center">
          <div @click.prevent="resetPhoneNumber"  class="cursor-pointer bg-red-900 w-8 h-8  pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded hover:border-b-red-950 border-b-stone-200">
            <img class="h-4 w-4" src="../../../assets/close.png" alt="Close"/>
          </div>
          <div v-if="isFieldEdited('phoneNumber')" @click.prevent="savePhoneNumber" class="cursor-pointer w-8 h-8 bg-green-900 pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded hover:border-b-green-950 border-b-stone-200">
            <img class="h-4 w-4" src="../../../assets/check.png" alt="Check"/>
          </div>
          <div v-else class="cursor-pointer w-8 h-8 bg-green-900 pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded opacity-50">
            <img class="h-4 w-4" src="../../../assets/check.png" alt="Check"/>
          </div>
        </div>
      </div>
    </div>
    <div class="dark:text-stone-100 shadow-own rounded pb-2 pt-4" :class="editHourlyWage ? 'pl-2 pr-2' : 'pl-4 pr-4'">
      <div class="flex justify-between items-center gap-1">
        <div class="w-5/6" :class="editHourlyWage ? 'relative' : ''">
          <label v-if="!editHourlyWage"  class="text-xs dark:text-stone-100">Uurloon</label>
          <h1 v-if="!editHourlyWage"  class="dark:text-stone-100 w-5/6   break-words text-xs md:text-base">€ {{ copyUser.hourlyWage }}</h1>
          <label v-if="editHourlyWage" class="p-2 label dark:text-stone-100" :class="{ 'active': copyUser.hourlyWage }" for="hourlyWage">Uurloon</label>
          <input v-if="editHourlyWage" v-model="copyUser.hourlyWage" @input="validateHourlyWage" :class="hourlyWageClass" class="w-full dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="hourlyWage"/>
          <p v-if="editHourlyWage && hourlyWageError" class="p-1 error-min-height text-red-500 text-xs italic"> {{ hourlyWageError }}</p>
        </div>
        <div v-if="!editHourlyWage" @click.prevent="toggleEditHourlyWage" class="cursor-pointer w-8 h-8  pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded hover:border-b-orange-600 border-b-stone-200">
          <img  class="h-4 w-4 select-none" :src="isDark ? require('../../../assets/edit-white.png') : require('../../../assets/edit-black.png')" alt="">
        </div>
        <div v-if="editHourlyWage" class="flex gap-2 items-center">
          <div @click.prevent="resetHourlyWage"  class="cursor-pointer bg-red-900 w-8 h-8  pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded hover:border-b-red-950 border-b-stone-200">
            <img class="h-4 w-4" src="../../../assets/close.png" alt="Close"/>
          </div>
          <div v-if="isFieldEdited('hourlyWage')" @click.prevent="saveHourlyWage" class="cursor-pointer w-8 h-8 bg-green-900 pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded hover:border-b-green-950 border-b-stone-200">
            <img class="h-4 w-4" src="../../../assets/check.png" alt="Check"/>
          </div>
          <div v-else class="cursor-pointer w-8 h-8 bg-green-900 pb-2 pt-2 pr-2 pl-2 dark:border-b-stone-600 border-b-4 shadow-own rounded opacity-50">
            <img class="h-4 w-4" src="../../../assets/check.png" alt="Check"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, inject, onMounted, reactive, ref} from "vue";
import {useDark} from "@vueuse/core";
import {useStore} from "vuex";
import router from "@/router";
import useEventsBus from "../../../../eventBus";

const {sessionSBService, userService} = inject('data')
const { emit } = useEventsBus();

const user = reactive( {
  id: '',
  email: '',
  firstname: '',
  lastname: '',
  phoneNumber: '',
  hourlyWage: '',
})
const copyUser = reactive({
  id: '',
  email: '',
  firstname: '',
  lastname: '',
  phoneNumber: '',
  hourlyWage: '',
});
const store = useStore();
const token = sessionSBService.getTokenFromBrowserStorage()
const isDark = useDark()
const editEmail = ref(false)
const editFirstname = ref(false)
const editLastname = ref(false)
const editPhoneNumber = ref(false)
const editHourlyWage = ref(false)
const emailError = ref("");
const emailValid = ref(null);
const firstnameError = ref("");
const firstnameValid = ref(null);
const lastnameError = ref("");
const lastnameValid = ref(null);
const phoneNumberError = ref("");
const phoneNumberValid = ref(null);
const hourlyWageError = ref("");
const hourlyWageValid = ref(null);


// const props = defineProps(['showRouters'])
const getUserByToken = async () => {
  try {
    const {data} = await userService.getUserByToken(token)
    Object.assign(user, { ...data });
    user.hourlyWage = user.hourlyWage.toLocaleString('nl-NL', { minimumFractionDigits: 2 });
    Object.assign(copyUser, {...user})
  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit('handleLogout')
      await router.push({path: '/inloggen'})
    }
    console.error(error)
  }
}

const saveEmail = async () => {
  try {
    copyUser.hourlyWage = parseFloat(copyUser.hourlyWage.replace(",","."))
    const {data, message} = await userService.updateUser(copyUser)
    sessionSBService.deleteTokenFromBrowserStorage()
    emit('handleLogout')
    Object.assign(user, { ...data });
    user.hourlyWage = user.hourlyWage.toLocaleString('nl-NL', { minimumFractionDigits: 2 });
    Object.assign(copyUser, {...user})
    editEmail.value = false;
    store.commit('setSuccessMessage', `${message} Login nu in met je nieuwe email.`)
    await router.push({path: '/inloggen'})
  } catch (e) {
    store.commit('setErrorMessage', e.message)
  }
}

const saveFirstname = async () => {
  try {
    copyUser.hourlyWage = parseFloat(copyUser.hourlyWage.replace(",","."))
    const {data, message} = await userService.updateUser(copyUser)
    Object.assign(user, { ...data });
    user.hourlyWage = user.hourlyWage.toLocaleString('nl-NL', { minimumFractionDigits: 2 });
    Object.assign(copyUser, {...user})
    editFirstname.value = false;
    store.commit('setSuccessMessage', message)
  } catch (e) {
    store.commit('setErrorMessage', e.message)
  }
}

const saveLastname = async () => {
  try {
    copyUser.hourlyWage = parseFloat(copyUser.hourlyWage.replace(",","."))
    const {data, message} = await userService.updateUser(copyUser)
    Object.assign(user, { ...data });
    user.hourlyWage = user.hourlyWage.toLocaleString('nl-NL', { minimumFractionDigits: 2 });
    Object.assign(copyUser, {...user})
    editLastname.value = false;
    store.commit('setSuccessMessage', message)
  } catch (e) {
    store.commit('setErrorMessage', e.message)
  }
}

const savePhoneNumber = async () => {
  try {
    copyUser.hourlyWage = parseFloat(copyUser.hourlyWage.replace(",","."))
    const {data, message} = await userService.updateUser(copyUser)
    Object.assign(user, { ...data });
    user.hourlyWage = user.hourlyWage.toLocaleString('nl-NL', { minimumFractionDigits: 2 });
    Object.assign(copyUser, {...user})
    editPhoneNumber.value = false;
    store.commit('setSuccessMessage', message)
  } catch (e) {
    store.commit('setErrorMessage', e.message)
  }
}

const saveHourlyWage = async () => {
  try {
    copyUser.hourlyWage = parseFloat(copyUser.hourlyWage.replace(",","."))
    const {data, message} = await userService.updateUser(copyUser)
    Object.assign(user, { ...data });
    user.hourlyWage = user.hourlyWage.toLocaleString('nl-NL', { minimumFractionDigits: 2 });

    Object.assign(copyUser, {...user})
    editHourlyWage.value = false;
    store.commit('setSuccessMessage', message)
  } catch (e) {
    store.commit('setErrorMessage', e.message)
  }
}


const resetEmail = () => {
  copyUser.email = user.email
  editEmail.value = false
}

const resetFirstname = () => {
  copyUser.firstname = user.firstname
  editFirstname.value = false
}

const resetLastname = () => {
  copyUser.lastname = user.lastname
  editLastname.value = false
}

const resetPhoneNumber = () => {
  copyUser.phoneNumber = user.phoneNumber
  editPhoneNumber.value = false
}

const resetHourlyWage = () => {
  copyUser.hourlyWage = user.hourlyWage
  editHourlyWage.value = false
}

const emailClass = computed(() => {
  if (emailValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
});

const firstnameClass = computed(() => {
  if (firstnameValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
});

const lastnameClass = computed(() => {
  if (lastnameValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
});

const phoneNumberClass = computed(() => {
  if (phoneNumberValid.value === false ) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
});

const hourlyWageClass = computed(() => {
  if (hourlyWageValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
});


const validateEmail = () => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (copyUser.email === "") {
    emailError.value = "Email mag niet leeg zijn.";
    emailValid.value = false;
  } else if (emailPattern.test(copyUser.email)) {
    emailError.value = "";
    emailValid.value = true;
  } else {
    emailError.value = "Ongeldig e-mailadres.";
    emailValid.value = false;
  }
}

const validateFirstname = () => {
  const firstnamePattern = /^[A-Z][a-zA-Z]*$/;
  if (copyUser.firstname === "") {
    firstnameError.value = "Voornaam mag niet leeg zijn.";
    firstnameValid.value = false;
  } else if (firstnamePattern.test(copyUser.firstname)) {
    firstnameError.value = "";
    firstnameValid.value = true;
  } else if (!/^[A-Z]/.test(copyUser.firstname)) {
    firstnameError.value = "Voornaam moet beginnen met een hoofdletter.";
    firstnameValid.value = false;
  } else {
    firstnameError.value = "Voornaam mag alleen alfabetische tekens bevatten.";
    firstnameValid.value = false;
  }
}

const validateLastname = () => {
  const lastnamePattern = /\b[A-Z][a-zA-Z]*\b/;
  if (copyUser.lastname === "") {
    lastnameError.value = "Achternaam mag niet leeg zijn.";
    lastnameValid.value = false;
  } else if (lastnamePattern.test(copyUser.lastname)) {
    lastnameError.value = "";
    lastnameValid.value = true;
  } else {
    lastnameError.value = "Achternaam moet minstens één woord met een hoofdletter bevatten.";
    lastnameValid.value = false;
  }
}

const validatePhoneNumber = () => {
  const localPhoneNumberPattern = /^0[1-9][0-9]{8}$/;
  const internationalPhoneNumberPattern = /^(?:\+31|0031)[1-9][0-9]{8}$/;
  if (copyUser.phoneNumber === "") {
    phoneNumberError.value = "Telefoonnummer mag niet leeg zijn.";
    phoneNumberValid.value = false;
  } else if (localPhoneNumberPattern.test(copyUser.phoneNumber) || internationalPhoneNumberPattern.test(copyUser.phoneNumber)) {
    phoneNumberError.value = "";
    phoneNumberValid.value = true;
  } else {
    phoneNumberError.value = "Ongeldig Nederlands telefoonnummer.";
    phoneNumberValid.value = false;
  }
}

const validateHourlyWage = () => {
  const hourlyWagePattern = /^[0-9]+([,.][0-9]{2})?$/;
  if (copyUser.hourlyWage === "") {
    hourlyWageError.value = "Uurloon mag niet leeg zijn.";
    hourlyWageValid.value = false;
  } else if (hourlyWagePattern.test(copyUser.hourlyWage)) {
    hourlyWageError.value = "";
    hourlyWageValid.value = true;
  } else {
    hourlyWageError.value = "Ongeldig uurloon. Gebruik precies twee cijfers na de komma indien aanwezig.";
    hourlyWageValid.value = false;
  }
}

const isFieldEdited = (field) => {
  return user[field] !== copyUser[field];
};

const toggleEditEmail = () => {
  editEmail.value = !editEmail.value
}

const toggleEditFirstname = () => {
  editFirstname.value = !editFirstname.value
}

const toggleEditLastname = () => {
  editLastname.value = !editLastname.value
}

const toggleEditPhoneNumber = () => {
  editPhoneNumber.value = !editPhoneNumber.value
}

const toggleEditHourlyWage = () => {
  editHourlyWage.value = !editHourlyWage.value
}





onMounted(() => {
  getUserByToken()
});
</script>



