import CustomError from "../../CustomError";

export class RoleService {
    url
    constructor(url) {
        this.url = url
    }

    async fetchJson(url, options) {
        try {
            const response = await fetch(url, options);

            if (response.ok) {
                return await response.json();
            }
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async getAllRoles() {
        try {
            return await this.fetchJson(`${this.url}`)
        } catch (e) {
            const customError = new CustomError(e.message, e.timestamp, e.details, e.status)
            throw customError.toJSON()
        }
    }
}