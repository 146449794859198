<template>
  <transition name="notification">
    <div v-if="successMessage" class="flex notification top-32 right-8 z-10 fixed bg-green-700 dark:bg-green-900 text-stone-100 rounded-2xl p-4">
      <span>{{ successMessage }}</span>
      <span @click="removeSuccessMessage" class="ml-2 cursor-pointer">
        <!-- Add your bin icon here -->
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </span>
    </div>
  </transition>
  <transition name="notification">
    <div v-if="errorMessage" class="flex notification top-28 right-8 fixed bg-red-700 dark:bg-red-900 text-stone-100 rounded-2xl p-4">
      <span>{{ errorMessage }}</span>
      <span @click="removeErrorMessage" class="ml-2 cursor-pointer">
        <!-- Add your bin icon here -->
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </span>
    </div>
  </transition>
</template>

<script setup>
import {useStore} from "vuex";
import {computed, watch} from "vue";
const store = useStore();
const successMessage = computed(() => store.state.successMessage);
const errorMessage = computed(() => store.state.errorMessage);

const removeSuccessMessage = () => {
  store.commit("setSuccessMessage", null);
};

const removeErrorMessage = () => {
  store.commit("setErrorMessage", null);
};


watch(successMessage, (newValue) => {
  if (newValue) {
    setTimeout(() => {
      removeSuccessMessage();
    }, 6000);
  }
});

watch(errorMessage, (newValue) => {
  if (newValue) {
    setTimeout(() => {
      removeErrorMessage();
    }, 6000);
  }
});
</script>