
<template>
  <table class="table-auto dark:text-stone-100 shadow-own">
    <thead class="border-black dark:border-white border-2">
    <tr>
      <th class="p-1 md:p-2 break-words whitespace-normal text-left">Datum</th>
      <th class="p-1 md:p-2 break-words whitespace-normal text-left">Vanaf</th>
      <th class="p-1 md:p-2 break-words whitespace-normal text-left">Tot</th>
      <th class="md:table-cell hidden p-1 md:p-2 break-words whitespace-normal text-left">Beschrijving</th>
      <th class="p-1 md:p-2 text-left">
        <button @click.prevent="createInternshipTime" class="rounded h-7 w-7 p-2 bg-green-500 shadow-own hover:bg-green-700 dark:bg-green-700 dark:hover:bg-green-900 duration-200">
          <img class="h-full w-full" src="../../../assets/plus-white.png" alt="Plus" />
        </button>
      </th>
    </tr>
    </thead>
    <tbody class="border-black dark:border-white border-2">
    <tr v-for="internshipTime in internshipTimes.list" :key="internshipTime.id">
      <td class="p-1 md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border break-words whitespace-normal">{{ formatDate(internshipTime.date) }}</td>
      <td class="p-1 md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border break-words whitespace-normal">{{ internshipTime.startTime }}</td>
      <td class="p-1 md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border break-words whitespace-normal">{{ internshipTime.endTime }}</td>
      <td class="md:table-cell hidden p-1 md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border break-words whitespace-normal" v-html="formatDescription(internshipTime.description)"></td>
      <td class="p-1 md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border">
        <div class="flex gap-1">
          <button @click.prevent="getInternshipTime(internshipTime.id)" class="rounded h-7 w-7 p-2 bg-black dark:bg-stone-100 shadow-own hover:bg-stone-700 dark:hover:bg-stone-300 duration-200">
            <img class="h-full w-full" :src="isDark ? require(`../../../assets/info-black.png`) : require('../../../assets/info-white.png')" alt="Info" />
          </button>
          <button @click.prevent="editInternshipTime(internshipTime.id)" class="rounded h-7 w-7 p-2 bg-yellow-500 shadow-own hover:bg-yellow-700 dark:bg-yellow-700 dark:hover:bg-yellow-900 duration-200">
            <img class="h-full w-full" src="../../../assets/edit-white.png" alt="Edit" />
          </button>
          <button @click.prevent="deleteInternshipTime(internshipTime.id)" class="rounded h-7 w-7 p-2 bg-red-500 shadow-own hover:bg-red-700 dark:bg-red-700 dark:hover:bg-red-900 duration-200">
            <img class="h-full w-full" src="../../../assets/bin.png" alt="Delete" />
          </button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
  <div v-if="totalPages > 1" class="flex justify-center gap-2 items-center mt-4">
    <!-- Previous page button -->
    <button @click="previousPage" :disabled="parseInt(currentPage) === 1" class="select-none w-28 px-4 py-2 bg-stone-100 dark:bg-stone-800 text-stone-800 dark:text-stone-100 disabled:opacity-50 shadow-own transition duration-300 hover:bg-orange-500 dark:hover:bg-orange-800 rounded disabled:dark:bg-stone-800 disabled:bg-stone-100">Vorige</button>
    <!-- Page buttons -->
    <div class="flex gap-2">
      <template v-if="parseInt(currentPage) > 1">
        <button v-if="parseInt(currentPage) > 2 && parseInt(currentPage) === parseInt(totalPages)" @click="goToPage(parseInt(currentPage) - 2)" class="select-none px-4 py-2 bg-stone-100 dark:bg-stone-800 text-stone-800 dark:text-stone-100 rounded shadow-own transition duration-300 hover:bg-orange-500 dark:hover:bg-orange-800 ">{{ parseInt(currentPage) - 2 }}</button>
        <button @click="goToPage(parseInt(currentPage) - 1)" class="select-none px-4 py-2 bg-stone-100 dark:bg-stone-800 text-stone-800 dark:text-stone-100 shadow-own transition duration-300 hover:bg-orange-500 dark:hover:bg-orange-800 rounded">{{ parseInt(currentPage) - 1 }}</button>
        <button @click="goToPage(parseInt(currentPage))" class="select-none px-4 py-2 bg-orange-500 dark:bg-orange-800 text-stone-100 shadow-own transition duration-300 hover:bg-orange-600 dark:hover:bg-orange-900 rounded">{{ parseInt(currentPage) }}</button>
        <button v-if="parseInt(currentPage) < parseInt(totalPages)" @click="goToPage(parseInt(currentPage) + 1)" class="select-none px-4 py-2 bg-stone-100 dark:bg-stone-800 text-stone-800 dark:text-stone-100 shadow-own transition duration-300 hover:bg-orange-500 dark:hover:bg-orange-800 rounded">{{ parseInt(currentPage) + 1 }}</button>
      </template>
      <template v-else>
        <button @click="goToPage(parseInt(currentPage))" class="select-none px-4 py-2 bg-orange-500 dark:bg-orange-800 text-stone-100  shadow-own transition duration-300 hover:bg-orange-500 dark:hover:bg-orange-800 rounded">{{ parseInt(currentPage) }}</button>
        <button v-if="parseInt(currentPage) < parseInt(totalPages)" @click="goToPage(parseInt(currentPage) + 1)" class="select-none px-4 py-2 bg-stone-100 dark:bg-stone-800 text-stone-800 dark:text-stone-100 shadow-own transition duration-300 hover:bg-orange-500 dark:hover:bg-orange-800 rounded">{{ parseInt(currentPage) + 1 }}</button>
        <button v-if="parseInt(currentPage) < parseInt(totalPages) - 1" @click="goToPage(parseInt(currentPage) + 2)" class="select-none px-4 py-2 bg-stone-100 dark:bg-stone-800 text-stone-800 dark:text-stone-100 shadow-own transition duration-300 hover:bg-orange-500 dark:hover:bg-orange-800 rounded">{{ parseInt(currentPage) + 2 }}</button>
      </template>
    </div>
    <!-- Next page button -->
    <button @click="nextPage" :disabled="parseInt(currentPage) === parseInt(totalPages)" class="select-none w-28 px-4 py-2 bg-gray-200 dark:bg-stone-800 text-gray-700 dark:text-gray-300 disabled:opacity-50 shadow-own transition duration-300 hover:bg-orange-500 dark:hover:bg-orange-800 rounded disabled:dark:bg-stone-800 disabled:bg-stone-100">Volgende</button>
  </div>
  <div class="my-4 shadow-own">
    <div class="table-auto dark:text-stone-100 shadow-own">
      <div class="border-black dark:border-white border-2">
        <div class="p-2 md:p-4 break-words whitespace-normal text-left">
          <strong>Aantal Dagen:</strong> {{ total }} Dagen
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {inject, onMounted, reactive, ref, watch} from "vue";
import { useDark } from "@vueuse/core";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import useEventsBus from "../../../../eventBus";
const { bus } = useEventsBus();

const { internshipTimeService, sessionSBService, userService } = inject("data");
const isDark = useDark();
const router = useRouter();
const { emit } = useEventsBus();
const route = useRoute();
const user = reactive({ id: "" });
const total = ref("");
const internshipTimes = reactive({ list: [] });
const totalPages = ref("");
const store = useStore();
const currentPage = ref(route.params.pageNo);
const token = sessionSBService.getTokenFromBrowserStorage();
const selectedInternship = ref(route.params.internshipId)
const selectedPageNo = ref(route.params.pageNo)
const getUserByToken = async () => {
  try {
    const { data } = await userService.getUserByToken(token);
    Object.assign(user, { ...data });
    await getAllInternshipTimesPageable();
  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit("handleLogout");
      await router.push({ path: "/inloggen" });
    }
    console.error(error);
  }
};

const editInternshipTime = async (internshipTimeId) => {
  await router.push(`/stagetijd/wijzigen/${internshipTimeId}/${selectedInternship.value}/${selectedPageNo.value}`);
};

const getInternshipTime = async (internshipTimeId) => {
  await router.push(`/stagetijd/${internshipTimeId}/${selectedInternship.value}/${selectedPageNo.value}`);
};

const nextPage = async () => {
  if (currentPage.value < total.value) {
    currentPage.value++;
    await router.push(`/stagetijden/${selectedInternship.value}/${currentPage.value}`);
    await getAllInternshipTimesPageable();
  }
};

const previousPage = async () => {
  if (currentPage.value > 0) {
    currentPage.value--;
    await router.push(`/stagetijden/${selectedInternship.value}/${currentPage.value}`);
    await getAllInternshipTimesPageable();
  }
};

const goToPage = async (page) => {
  currentPage.value = page;
  await router.push(`/stagetijden/${selectedInternship.value}/${currentPage.value}`);
  await getAllInternshipTimesPageable();
};

const formatDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
};

const deleteInternshipTime = async (internShipTimeId) => {
  try {
    const { message } = await internshipTimeService.removeInternshipTime(internShipTimeId);
    store.commit("setSuccessMessage", message);
    await getAllInternshipTimesPageable();
  } catch (e) {
    store.commit("setErrorMessage", e.message);
  }
};

const createInternshipTime = async () => {
  await router.push(`/stagetijd/aanmaken/${selectedInternship.value}/${currentPage.value}`);
};

const getAllInternshipTimesPageable = async () => {
  try {
    if (selectedPageNo.value > 0) {
      const { data } = await internshipTimeService.getInternshipTimeByInternshipPageable(selectedInternship.value, selectedPageNo.value);
      internshipTimes.list = data.content.map((w) => ({ ...w }));
      totalPages.value = data.totalPages;
      total.value = data.totalElements || 0
      if (totalPages.value < selectedPageNo.value) {
        await router.push(`/stagetijden/${selectedInternship.value}/${totalPages.value}`);
        const { data } = await internshipTimeService.getInternshipTimeByInternshipPageable(selectedInternship.value, selectedPageNo.value);
        internshipTimes.list = data.content.map((w) => ({ ...w }));
        totalPages.value = data.totalPages;
        total.value = data.totalElements

      }

    } else {
      internshipTimes.list = []
      totalPages.value = ''
      total.value = ''
    }
  } catch (e) {
    console.error(e);
  }
};

watch(() => route.params.pageNo, async () => {
  selectedPageNo.value = route.params.pageNo
  currentPage.value = route.params.pageNo
});

watch(() => bus.value.get('updateSelectedInternship'), async (newValue) => {
  selectedInternship.value = newValue
  await getAllInternshipTimesPageable()
});

onMounted(() => {
  getUserByToken();
});

const formatDescription = (description) => {
  if (description !== undefined) {
    return description.replace(/\n/g, "<br>");
  }
};
</script>
